import React from 'react';
import './App.css';
import { Routes, Route, Navigate } from "react-router-dom";
import Registration from "./components/Registration";
import Guidance from "./pages/Guidance";
import Environnement from "./pages/Environnement";
import Testing from './pages/Testing';
import SujetsPreferes from './pages/SujetsPreferes';
import SearchSystemFormation from "./pages/SearchSystemFormation";
import SavedItems from "./pages/SavedItems";
import MetierDetailPage from "./pages/MetierDetailPage";
import ErrorPage from './pages/ErrorPage';
import { ProgressProvider } from './context/ProgressContext';
import LandingPage from './pages/LandingPage';
import SearchSystemmetier from "./pages/SearchSystemMetier";
import QuiCeQueIkigai from "./pages/QuiCeQueIkigai";
import SignUp from './pages/signup';
import PrivateRoute from './utils/routes/PrivateRoute';
import { AuthProvider } from './services/auth.provider';

function App() {
  // questionsScores
  return (
    <AuthProvider>
      <ProgressProvider>
        <Routes>
            <Route index element={<Navigate to="landing" replace />} />
            <Route path="/public" element={<Testing />} />
            <Route path="/public/moteur" element={<SujetsPreferes />} />
            <Route 
              path="/guidance" 
              element={
                <PrivateRoute> 
                  <Guidance /> 
                </PrivateRoute>
              } 
            />          
          <Route path='/register' element={<SignUp />} />
          <Route path="/public/registration" element={<Registration />} />
          <Route path="/environnement" element={<Environnement />} />
          <Route path="/search" element={<SearchSystemFormation />} />
          <Route 
            path="/saveditems/:userId" 
            element={
              <PrivateRoute> 
                <SavedItems /> 
              </PrivateRoute>
            } 
          />
          <Route path="/metiers" element={<SearchSystemmetier />} />
          <Route path="/details/:professionId" element={<MetierDetailPage />} />
          <Route path="/igikai" element={<QuiCeQueIkigai />} />
          <Route path="*" element={<ErrorPage />} />
          <Route path="/landing" element={<LandingPage />} />
        </Routes>
      </ProgressProvider>
    </AuthProvider>
  );
}

export default App;
