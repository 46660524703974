import React, { useState, useEffect } from "react";
import LayoutNo from "../utils/LayoutNo";
import Bgimg from "../images/searchametierbg.svg";
import BrushIcon from "../images/brush.svg";
import MetierCard from "../utils/MetierCard";
import Searchicon from "../images/search";
import { MetierService } from "../services/metier.service";
import CustomSelectSearch from "../components/dropDown/CustomSelectSearch";
import ArrowDown from "../images/arrowDown";
import { UserService } from "../services/userforms.service";
import LoginModal from "../components/modal/auth/LoginModal";
import {ItemsService} from "../services/ items.service";
import CarouselSlider from "../utils/CarouselMetiers";
import CarouselSecteur from "../utils/CarouselSecteur";
import CarouselFiliere from "../utils/CarouselFiliere";
import {useRef} from "react";
import { Toast } from 'primereact/toast';
import { CryptoService } from "../services/crypte.service";
import NodataFound from "../utils/NoDataFound";
import { notification } from 'antd';

export default function SearchSystemmetier() {
    const [visibleContent, setVisibleContent] = useState('Métiers');
    const [metiers, setMetiers] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedValue, setSelectedValue] = useState('Metiers');
    const [isSalaireOpen, setIsSalaireOpen] = useState(false);
    const [studyLevels, setStudyLevels] = useState([]);
    const [salaryRanges, setSalaryRanges] = useState([]);
    const [businessSector, setBusinessSectors] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedStudyLevel, setSelectedStudyLevel] = useState('');
    const [selectedBusinessSector, setSelectedBusinessSector] = useState('');
    const [userId, setUserId] = useState(0);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [seniorPosition, setSeniorPosition] = useState(0);
    const [selectedSalaryRange, setSelectedSalaryRange] = useState('');
    const [showNoSelectionCard, setShowNoSelectionCard] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const [keywords, setKeywords] = useState('');
    const [searchKeywords, setSearchKeywords] = useState('');
    const toast = useRef(null);
    const [clearFilters, setClearFilters] = useState(false);

    useEffect(() => {
        const user = localStorage.getItem('user_id');
        if (user) {
            const decryptedId = CryptoService.decryptData(user)
            console.log("use id",decryptedId)
            setUserId(decryptedId)
        }
    }, []);

    useEffect(() => {
        const fetchStudyLevels = async () => {
            try {
                const response = await UserService.getStudiesLevel(0, 1);
                setStudyLevels(response.data.data);
                console.log(response.data.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
            }
        };

        const fetchSalaryRanges = async () => {
            try {
                const response = await UserService.getSalaireRange();
                setSalaryRanges(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.error("Error fetching salary ranges:", error);
            }
        };

        const fetchBusinessSector = async () => {
            try {
                const response = await UserService.getBusinessSector();
                setBusinessSectors(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.error("Error fetching business sectors:", error);
            }
        };

        fetchBusinessSector();
        fetchStudyLevels();
        fetchSalaryRanges();
    }, []);

    useEffect(() => {
        if (
            (selectedStudyLevel && selectedBusinessSector && selectedSalaryRange && seniorPosition !== undefined) ||
            (seniorPosition && selectedSalaryRange && searchKeywords) ||
            (selectedStudyLevel && searchKeywords) ||
            (selectedBusinessSector && searchKeywords)||
            searchKeywords

        ) {
            setShowNoSelectionCard(false);
            setLoading(true);

            MetierService.searchProfessions(
                selectedStudyLevel,
                selectedBusinessSector,
                seniorPosition,
                selectedSalaryRange,
                searchKeywords,
                null,
                userId,
            )
                .then((response) => {
                    if (response.data && response.data.status === "success") {
                        setMetiers(response.data.data && response.data.data.length > 0 ? response.data.data : []);
                    } else {
                        setMetiers([]);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching professions:", error);
                    setMetiers([]);
                })
                .finally(() => {
                    setLoading(false);
                });
        }
    }, [selectedStudyLevel, selectedBusinessSector, seniorPosition, selectedSalaryRange, searchKeywords]);


    const handleOptionClick = (option) => {
        setSelectedSalaryRange(option.salary_range_id);
        setSelectedOption(option.salary_range);
        console.log("salaire",option.salary_range_id)
        setIsSalaireOpen(false);
    };

    const handleClearAll = () => {
        setSelectedStudyLevel('');
        setSelectedBusinessSector('');
        setSelectedSalaryRange('');
        setSeniorPosition(0);
        setMetiers([]);
        setSearchKeywords('');
        setKeywords('');
        setSelectedOption('');
        setClearFilters(true);
        setTimeout(() => {
            setClearFilters(false);
        }, 0);
        setShowNoSelectionCard(true);



    };
    const handleSearch = () => {
        setSearchKeywords(keywords);
    };
    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSearch();  // Trigger search when Enter key is pressed
        }
    };
    const handleStudyLevelChange = (selectedOption) => {
        setSelectedStudyLevel(selectedOption ? selectedOption.studlevel_id : '');
        console.log("level", selectedOption ? selectedOption.studlevel_id : 'none');
    };

    const handleBusinessSectorChange = (selectedOption) => {
        setSelectedBusinessSector(selectedOption ? selectedOption.business_sect_id : '');
        console.log("sector", selectedOption ? selectedOption.business_sect_id : 'none');
    };


    const toggleSalaireDropdown = () => setIsSalaireOpen(!isSalaireOpen);

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const handleSaveProfession = async (professionId) => {
        if (!userId) {
            showModal();
            return;
        }
        try {
            await ItemsService.saveProfession(userId, professionId);
            notification.success({
                message: 'Action Complète',
                description: 'La profession a été enregistrée avec succès!',
                placement: 'topRight',
            });
        } catch (error) {
            console.error('Error saving profession:', error);
            notification.error({
                message: 'Erreur',
                description: 'Une erreur est survenue lors de la sauvegarde de la profession.',
                placement: 'topRight',
            });
        }
    };

    const handleDeleteProfession = async (professionId) => {
        if (!userId) {
            showModal();
            return;
        }
        try {
            await ItemsService.deleteProfession(userId, professionId);
            notification.info({
                message: 'Action Complète',
                description: 'La profession a été supprimée avec succès!',
                placement: 'topRight',
            });
        } catch (error) {
            console.error('Error deleting profession:', error);
        }
    };
    const handleSeniorPositionToggle = () => {
        setSeniorPosition(prev => (prev === 0 ? 1 : 0));
        console.log("senior" , seniorPosition)
    };




    const handleSelect = (value) => {
        setSelectedValue(value);
        setIsOpen(false);
    };
    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };


    const showPopup = () => {
        notification.info({
            message: 'Bientôt Disponible',
            description: 'Cette fonctionnalité sera bientôt disponible.',
            placement: 'top',

        });
        setIsOpen(false);

    }
    return (
        <LayoutNo>
            <Toast ref={toast} position="top-center" />

            <div className="mt-5 md:mt-2 w-full">
                <div
                    className="relative w-full h-48  rounded-xl bg-cover bg-center"
                    style={{ backgroundImage: `url(${Bgimg})` }}
                >

                    <div className="absolute top-8 left-0  right-0 flex justify-center items-center">
                        <p className="font-bold md:text-5xl text-[#141943]">Explorez parmi plus de 2 000 métiers</p>
                    </div>

                    <div className="absolute mt-5 inset-0 flex items-center justify-center">

                        <div className="bg-white w-3/4 md:w-2/3 lg:w-2/4   rounded-lg shadow-lg flex items-center space-x-2 p-1">

                            <div className="flex-grow">
                                <input
                                    type="text"
                                    value={keywords}
                                    placeholder="Recherche par mot-clé..."
                                    onKeyUp={handleKeyPress}
                                    onChange={(e) => setKeywords(e.target.value)}
                                    className="w-full p-1 text-sm text-gray-700 rounded-l focus:outline-none"

                                />
                            </div>

                            <button type="button" onClick={handleSearch} className="flex shrink-0 justify-center items-center gap-2 size-[38px] text-sm font-medium rounded-lg border border-blue-500 bg-transparent text-white   ">
                                <Searchicon/>
                            </button>


                            <div className="relative inline-block text-left">
                                <button
                                    id="dropdownDefaultButton"
                                    onClick={toggleDropdown}
                                    type="button"
                                    className="text-white bg-[#1681FF] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center pr-5">
                                    {selectedValue}
                                    <svg width="21" height="20" className="ml-2" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.5 7L10.5 14L3.5 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                    </svg>

                                </button>

                                {isOpen && (
                                    <div className="absolute mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20">
                                        <div className="py-1">
                                            <button
                                                onClick={() => handleSelect('Metiers')}
                                                className="block px-4 py-2 text-sm text-gray-700"
                                            >
                                                Metiers
                                            </button>
                                            <button
                                                onClick={showPopup}
                                                className="block px-4 py-2 text-sm text-gray-700 "
                                            >
                                                Formations
                                            </button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>


                <div className="p-1 -mt-4">

                    <div className="w-full flex flex-wrap justify-between mt-5 items-center py-2">
                        <div>
                            <div className="w-full flex flex-1 justify-between items-center py-2">
                                <div className="flex flex-wrap justify-start gap-2 w-full lg:w-auto">
                                    <div className="w-full sm:w-1/2 lg:w-auto">
                                        <CustomSelectSearch
                                            options={studyLevels}
                                            optionKey="studlevel_id"
                                            optionLabel="study_level"
                                            placeholder="Niveau d'études"
                                            onChange={handleStudyLevelChange}
                                            clearFilters={clearFilters}

                                        />
                                    </div>
                                    <div className="w-full sm:w-1/2 lg:w-auto">
                                        <CustomSelectSearch
                                            options={businessSector}
                                            optionKey="business_sect_id"
                                            optionLabel="business_sect"
                                            placeholder="Secteur d'activité"
                                            onChange={handleBusinessSectorChange}
                                            clearFilters={clearFilters}

                                        />
                                    </div>
                                    <div className="w-full sm:w-1/2 lg:w-auto relative">
                                        <div className="relative   md:w-56">
                                            <button
                                                className="flex justify-between items-center w-full px-4 py-2.5 border border-[#D0DBE7] bg-white rounded-lg focus:outline-none"
                                                onClick={toggleSalaireDropdown}
                                            >
                                                {selectedOption ? selectedOption : "Salaire"}
                                                <span className="ml-2"><ArrowDown /></span>
                                            </button>
                                            {isSalaireOpen && (
                                                <div
                                                    className="absolute left-0 mt-2 w-full bg-white border border-[#D0DBE7] rounded-lg shadow-lg z-20"
                                                >
                                                    <div className="flex items-center justify-between px-2 py-3 border-1 rounded-t-md border-b-[#D0DBE7] bg-white">
                                                        <label className="text-black text-xs font-inter">Débutant</label>
                                                        <div
                                                            className={`relative border-1 border-[#235C9B] w-14 h-4 flex items-center  rounded-full p-1 cursor-pointer transition-colors ${
                                                                seniorPosition === 0 ? 'bg-[#E5EDF6]' : 'bg-gray-300'
                                                            }`}
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                handleSeniorPositionToggle();
                                                            }}
                                                        >
                                                            <div
                                                                className={`bg-[#235C9B] w-4 h-3  rounded-full shadow-md transform transition-transform ${
                                                                    seniorPosition === 1 ? 'translate-x-8' : 'translate-x-0'
                                                                }`}
                                                            ></div>
                                                        </div>
                                                        <label className="text-black text-xs font-inter">Expérimenté</label>
                                                    </div>
                                                    <ul className="max-h-32 overflow-y-auto py-2">
                                                        {salaryRanges.map((range) => (
                                                            <li
                                                                key={range.salary_range_id}
                                                                onClick={() => handleOptionClick(range)}
                                                                className={`flex items-center p-2 cursor-pointer hover:bg-gray-100 ${
                                                                    selectedOption && selectedOption.salary_range_id === range.salary_range_id ? 'bg-blue-50' : ''
                                                                }`}
                                                            >
                                                                <span className="text-sm text-gray-800"> {range.salary_range}</span>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </div>
                                            )}
                                        </div>
                                        </div>
                                </div>

                            </div>
                        </div>
                        <div className="  justify-end mt-2 lg:mt-0  ">
                            <button onClick={handleClearAll} className="flex gap-2 text-xs px-2 py-2 border-2 rounded-md shadow-sm hover:bg-gray-300">
                                <img src={BrushIcon} alt="edit" className="w-6 h-6" />
                                <span className="whitespace-nowrap p-1">Tout effacer</span>
                            </button>
                        </div>
                    </div>
                    {showNoSelectionCard && (

                        <div>
                            <div className="justify-start">
                                <p className="font-semibold font-poppins text-[28px] text-[#141943] p-1">
                                    Métiers populaires
                                </p>
                            </div>
                            <CarouselSlider/>
                            <CarouselSecteur/>
                            <CarouselFiliere/>
                        </div>
                    )}
                    {loading ? (
                        <div className="grid grid-cols-1 flex justify-center  gap-2 mt-5 p-2">
                            <div
                                className="  animate-spin inline-block size-6 border-[3px] border-current border-t-transparent text-blue-600 rounded-full dark:text-blue-500"
                                role="status" aria-label="loading">
                            </div>
                        </div>
                    ) : metiers.length === 0 && (selectedStudyLevel && selectedBusinessSector && selectedSalaryRange  || selectedStudyLevel && searchKeywords || selectedBusinessSector && searchKeywords || searchKeywords ) ? (
                        <div className="mt-4">
                            <NodataFound clearFilter={handleClearAll}/>
                        </div>
                    ) : visibleContent === 'Métiers' ? (
                        <div>


                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 mt-2 p-2">
                                {metiers.map((metier) => (
                                    <MetierCard
                                        key={metier.profession_id}
                                        imgSrc={metier.pictact}
                                        smtitle={metier.business_sect}
                                        title={metier.profession}
                                        chips={[metier.required_stud_level]}
                                        frompriceDebutant={metier.salary_1}
                                        topriceDebutant={metier.salary_2}
                                        frompriceExp={metier.salary_3}
                                        topriceExp={metier.salary_4}
                                        professionId={metier.profession_id}
                                        saved={metier.saved} // Pass the save attribute
                                        saveProfession={handleSaveProfession}
                                        deleteProfession={handleDeleteProfession}
                                    />
                                ))}
                            </div>
                        </div>

                    ) : (
                        <div>Content for Formations</div>
                    )}
                </div>

                <LoginModal visible={isModalVisible} onClose={hideModal} />
            </div>
        </LayoutNo>
    );
}
