import React, { useState } from 'react';
import Modal from 'react-modal';
import GoogleIcon from "../../../images/google.svg";
import EyeHide from "../../../images/eye-hide.svg";
import EyeShow from "../../../images/eye-view.svg";
import { Link } from 'react-router-dom';
import { ResetPasswordModal } from './ResetPasswordModal';
import warningIcon from '../../../images/modal/warning.svg';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../../services/auth.provider';
import { GoogleLogin } from '@react-oauth/google';
import ConditionsGeneralesDialog from "../../../utils/ConditionsGenerales";

Modal.setAppElement('#root');

export function LoginModal({ visible, onHide }) {
    const [showPassword, setShowPassword] = useState(false);
    const [resetPasswordVisible, setResetPasswordVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [notFound, setNotFound] = useState(false);
    const [emptyValue, setEmptyValue] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const navigate = useNavigate();
    const { login, googleLogin } = useAuth();
    const [openConditionsDialog, setOpenConditionsDialog] = useState(false);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleResetPassword = () => {
        setResetPasswordVisible(true);
        handleClose();
    };

    const handleLogin = async (e) => {
        setEmptyValue(false);
        e.preventDefault();
        setIsLoading(true);

        if (!email || !password) {
            setEmptyValue(true); // Show warning if fields are empty
            setIsLoading(false); // Reset loading state
            return;
        }

        try {
            const response = await login(email, password);
            if (response.status === 'success') {
                handleClose();
                setNotFound(false);
                setIsLoading(false);
                navigate("/igikai");
            } else {
                setNotFound(true);
                setIsLoading(false);  // Ensure isLoading is set to false in case of failure
                console.log(response.message || 'An error occurred');
            }
        } catch (error) {
            setNotFound(true);
            setIsLoading(false);  // Reset isLoading if there's an error
            console.log('Login failed', error);
        }
    };

    const handleGoogleSuccess = async (response) => {
        const token = response.credential;
        try {
            console.log(response);
            await googleLogin(token);
        } catch (error) {
            console.error('Google login failed:', error);
        }
    };

    const handleGoogleFailure = (error) => {
        // Handle Google login failure
        console.error(error);
    };

    const handleClose = () => {
        setEmail('');
        setPassword('');
        setEmptyValue(false);
        setShowPassword(false);
        setNotFound(false);
        setIsLoading(false);  // Ensure loading state is reset
        onHide();
    };

    return (
        <>
            <Modal
                isOpen={visible}
                onRequestClose={handleClose}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.7)', // Dark gray background
                    },
                    content: {
                        top: '10%', // Margin top
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, 0)',
                        width: '90%', // Responsive width
                        maxWidth: '500px', // Max width for larger screens
                        padding: '1.5rem',
                        borderRadius: '0.75rem',
                        backgroundColor: 'white',
                        textAlign: 'center',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        alignItems: 'center',
                        boxSizing: 'border-box',
                    },
                }}
            >
                <button
                    type="button"
                    onClick={handleClose}
                    className="absolute top-2 right-2 text-2xl leading-none text-gray-600 hover:text-gray-800 focus:outline-none"
                >
                    &times;
                </button>

                <h2 className="text-xl font-semibold mb-4">Connexion</h2>

                {emptyValue && (
                    <div className="bg-red-50 text-red-500 px-4 py-1 rounded-md flex items-center mb-2">
                        <img src={warningIcon} alt="Warning" className="mr-2" />
                        <span>Veuillez remplir tous les champs</span>
                    </div>
                )}

                {notFound && (
                    <div className="bg-red-50 text-red-500 px-2 py-2 rounded-md flex items-center mb-2">
                        <img src={warningIcon} alt="Warning" className="mr-2" />
                        <span>Vérifiez les données et réessayez</span>
                    </div>
                )}

                <div className="mb-3 w-full">
                    <label htmlFor="email" className="block text-sm font-medium mb-1 text-left">E-mail</label>
                    <input
                        type="email"
                        id="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        className="py-2 px-4 block w-full border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring focus:ring-blue-500 transition ease-in-out"
                        placeholder="you@linkyways.com"
                    />
                </div>

                <div className="mb-3 w-full">
                    <label htmlFor="password" className="block text-sm font-medium mb-1 text-left">Mot de passe</label>
                    <div className="relative">
                        <input
                            type={showPassword ? 'text' : 'password'}
                            id="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="py-2 px-4 block w-full border border-gray-300 rounded-lg text-sm focus:border-blue-500 focus:ring focus:ring-blue-500 transition ease-in-out"
                            placeholder="********"
                        />
                        <button
                            type="button"
                            onClick={togglePasswordVisibility}
                            className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500"
                        >
                            <img src={showPassword ? EyeShow : EyeHide} alt={showPassword ? 'Afficher le mot de passe' : 'Masquer le mot de passe'} className="h-5 w-5" />
                        </button>
                    </div>
                    <div className="text-right mt-2">
                        <span className="text-sm text-[#434769] cursor-pointer" onClick={handleResetPassword}>
                            Mot de passe oublié?
                        </span>
                    </div>
                </div>

                <button
                    type="button"
                    onClick={handleLogin}
                    disabled={isLoading}
                    className={`w-full py-2 px-4 text-white font-medium rounded-lg ${isLoading ? 'bg-[#1681FF] cursor-not-allowed' : 'bg-[#1681FF] hover:bg-blue-700'} focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition ease-in-out mb-4`}
                >
                    {isLoading ? 'Chargement...' : 'Se connecter'}
                </button>

                <p className="text-xs font-inter text-gray-500 mb-2">
                    En cliquant sur "Se connecter", vous acceptez les <a onClick={() => setOpenConditionsDialog(true)} className="text-blue-500 cursor-pointer">CGU</a> de Linkyways.
                </p>

                <div className="flex items-center justify-center mb-3 w-full ">
                    <span className="mx-4 font-bold text-gray-900">OU</span>
                </div>

                <div className="flex flex-center justify-center gap-2 mb-4  w-full">
                    <GoogleLogin
                        onSuccess={handleGoogleSuccess}
                        onError={handleGoogleFailure}
                        render={(renderProps) => (
                            <button
                                onClick={handleGoogleSuccess}
                                disabled={renderProps.disabled}
                                // className="flex justify-center items-center gap-2 py-2 px-8 text-sm font-medium rounded-full border border-gray-300 bg-white text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition ease-in-out w-full"
                            >
                                <img src={GoogleIcon} alt="Google" className="h-5" />
                                <span>Continuer avec Gmail</span>
                            </button>
                        )}
                    />
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    className="flex justify-center items-center gap-2 py-2 px-4 text-sm font-medium rounded-full border border-gray-300 bg-white text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition ease-in-out w-full"*/}
                    {/*>*/}
                    {/*    <img src={AppleIcon} alt="Apple" className="h-5" />*/}
                    {/*    <span>Continuer avec Apple</span>*/}
                    {/*</button>*/}
                </div>
                <p className="text-sm text-[#434769] cursor-pointer">
                    Pas encore de compte ?{' '}
                    <a
                          onClick={() => window.location.href = '/public/registration'}
                          className="text-sm font-bold underline">
                        S'inscrire
                    </a>
                </p>
            </Modal>
            <ResetPasswordModal visible={resetPasswordVisible} onHide={() => setResetPasswordVisible(false)} />
            <ConditionsGeneralesDialog open={openConditionsDialog} onClose={() => setOpenConditionsDialog(false)} />

        </>
    );
}

export default LoginModal;
