import React, { useState, useEffect, useRef } from 'react';
import logo from "../images/logo.svg";
import UserIcon from "../images/iconuser.svg";
import { Link } from 'react-router-dom';
import LoginModal from './modal/auth/LoginModal';
import { Toast } from 'primereact/toast';
import {notification} from "antd";

export default function LandingNav() {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const menuRef = useRef(null);
    const buttonRef = useRef(null);
    const toast = useRef(null);



    const handleToggleMenu = () => {
        setIsMenuOpen(prev => !prev);
    };

    const showModal = () => {
        setIsModalVisible(true);
        setIsMenuOpen(false); // Close dropdown when opening modal
    };

    const hideModal = () => setIsModalVisible(false);

    const showPopup = () => {
        notification.info({
            message: 'Bientôt Disponible',
            description: 'Cette fonctionnalité sera bientôt disponible.',
            placement: 'top',
        });
    }



    useEffect(() => {
        const handleClickOutside = (event) => {
            if (menuRef.current && !menuRef.current.contains(event.target) && !buttonRef.current.contains(event.target)) {
                setIsMenuOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <nav className="bg-white p-2 flex justify-between items-center fixed top-0 left-0 w-full z-50">
            <Toast ref={toast} position="top-center" />

            <div>
                <Link to="/landing">
                    <img
                        alt="logo"
                        src={logo}
                        width="170"
                        className="pl-3 cursor-pointer "
                    />
                </Link>
            </div>

            {/* Large screens: Buttons are shown normally */}
            <div className="hidden md:flex items-center">
                <Link to="/public" className=" px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                    Test d'orientation
                </Link>
                <Link to="/metiers" className=" px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                    Métiers
                </Link>

                <Link onClick={showPopup}  className=" px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                    Formations
                </Link>
                <Link to="/public/registration" className=" px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold">
                    S'inscrire
                </Link>
                <div
                    onClick={showModal}
                    className="cursor-pointer flex items-center border border-gray-300 px-4 py-2 rounded-full transition font-poppins text-[#141943] font-semibold"
                >
                    <span>S'identifier</span>
                    <img
                        src={UserIcon}
                        alt="Login Icon"
                        className="ml-2 h-5 w-5"
                    />
                </div>
            </div>

            {/* Small screens: Hamburger icon */}
            <div className="md:hidden">
                <button
                    onClick={handleToggleMenu}
                    ref={buttonRef} // Attach ref to the button
                >
                    <svg
                        className="w-6 h-6"
                        fill="none"
                        stroke="currentColor"
                        viewBox="0 0 24 24"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M4 6h16M4 12h16m-7 6h7"
                        ></path>
                    </svg>
                </button>
            </div>

            {/* Dropdown menu for small screens */}
            {isMenuOpen && (
                <div
                    ref={menuRef} // Attach ref to the dropdown menu
                    className="absolute top-10 right-0 mt-2 w-48 bg-white shadow-lg rounded-lg py-2 flex flex-col items-start md:hidden"
                >
                    <Link to="/metiers" className=" px-4 py-2 w-full text-left transition font-poppins text-[#141943] font-semibold">
                        Métiers
                    </Link>
                    <Link to="/public" className=" px-4 py-2 w-full text-left transition font-poppins text-[#141943] font-semibold">
                        Test d'orientation
                    </Link>
                    <Link onClick={showPopup} className=" px-4 py-2 w-full text-left transition font-poppins text-[#141943] font-semibold">
                        Formations
                    </Link>
                    <Link to="/public/registration" className=" px-4 py-2 w-full text-left transition font-poppins text-[#141943] font-semibold">
                        S'inscrire
                    </Link>
                    <hr className="border-1 px-1 border-gray-400 w-full mt-3 "/>
                    <div
                        onClick={showModal}
                        className=" px-4 mt-1 border-1 rounded-full py-2 w-full text-left flex items-center transition font-poppins text-[#141943] font-semibold"
                    >
                        <img
                            src={UserIcon}
                            alt="Login Icon"
                            className=" h-5 mr-2"
                        />
                        <span>S'identifier</span>

                    </div>
                </div>
            )}

            <LoginModal visible={isModalVisible} onHide={hideModal} />
        </nav>
    );
}
