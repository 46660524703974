import React, {useEffect, useState} from "react";
import TraitsDeCaractere from "../dashboardComponents/TraitsDeCaractere";
import Categories from "../dashboardComponents/Categories";
import TabContent from "../utils/TabContent";
import LayoutNo from "../utils/LayoutNo";
import {UserService} from "../services/userforms.service";
import { CryptoService } from "../services/crypte.service";

export default function Guidance() {

    const [activeTab, setActiveTab] = useState(1);
    const [profileDescription, setProfileDescription] = useState("");
    const decryptedId = CryptoService.decryptData(localStorage.getItem('user_id'))
    const [userId] = useState(() => decryptedId);

    useEffect(() => {
        if (userId) {
            UserService.getUserSubmissionData(userId)
                .then((response) => {
                    if (response.data.status === "success" && response.data.data.user_personality.length > 0) {
                        setProfileDescription(response.data.data.profile_description || "Description not available.");
                    } else {
                        console.error("Unexpected response format or empty personality data:", response.data);
                        setProfileDescription("Description not available.");
                    }
                })
                .catch((error) => {
                    console.error("Error fetching user submission data:", error);
                    setProfileDescription("Error fetching profile description.");
                });
        }
    }, [userId]);


    return (

        <LayoutNo>
            <div className="grid grid-cols-1 lg:grid-cols-3 mt-5 md:mt-2 p-2 w-full justify-center mx-auto">
                {/* Main content */}
                <div className="lg:col-span-2 w-full xl:pl-20  2xl:pl-28">
                        {/*<p className="font-bold text-2xl px-2">Resultats</p>*/}

                        <div className="  card shadow-sm bg-[#E9F2FB] border-1 border-blue-100 rounded-lg p-3 mx-2">
                            <p className="font-bold text-xl">Votre Profil </p>
                            <p className="font-light text-sm">
                                    {profileDescription}
                            </p>
                    </div>
                    {/* Sidebar content for small screens */}
                    <div className="lg:hidden p-1 px-2 ">
                        <div className="space-y-2">
                            <div className="card bg-white border-2 shadow-sm rounded-lg p-2 border-gray-300">
                                <h2 className="text-2xl font-bold mb-2">Traits de caractères</h2>
                                <TraitsDeCaractere userId={userId}/>
                            </div>

                            <div className="card bg-white border-2 shadow-sm rounded-lg p-2 border-gray-300">
                                <Categories userId={userId}/>
                            </div>

                        </div>
                    </div>

                    {/* Tab Content */}
                    <div className="p-2 mt-2 mb-10">
                        <div className="flex border-b border-gray-200">
                            <button
                                className={`py-2 px-4 text-sm font-medium ${activeTab === 1 ? 'border-2 border-gray-300 border-b-0 text-blue-600 rounded-t-lg' : 'text-gray-600'} focus:outline-none`}
                                onClick={() => setActiveTab(1)}
                            >
                                Métiers
                            </button>
                            <button
                                className={`py-2 px-4 text-sm font-medium ${activeTab === 2 ? 'border-2 border-gray-300 border-b-0 text-blue-600 rounded-t-lg' : 'text-gray-600'} focus:outline-none`}
                                onClick={() => setActiveTab(2)}
                            >
                                Formation
                            </button>
                        </div>
                        <div className="mt-1 ">
                            {activeTab === 1 && (
                                <TabContent
                                    userId={userId}
                                />
                            )}
                            {activeTab === 2 && (
                                <div className="mt-5 flex justify-center">
                                    {/*<FormationTab />*/}
                                    <h1>Bientôt disponible</h1>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <aside className="hidden lg:flex w-auto bg-white flex-col h-screen lg:fixed lg:right-0 top-8">
                    <div className="w-[300px]  ">
                        <div className="card p-3 shadow-sm bg-white border-2 lg:border-none border-gray-300 mb-2">
                            <div className="card  rounded-lg p-1.5 mt-2  flex justify-center bg-white">
                                <button className="w-full  bg-blue-500 p-2 border-1 rounded-md text-white font-bold text-ms">
                                    Repasser le test
                                </button>
                            </div>
                            <h2 className="text-md font-poppins font-bold mb-1 mt-2 px-3 text-[#141943]">Traits de caractères </h2>
                            <TraitsDeCaractere userId={userId}  />
                        </div>

                        <div className="card shadow-sm rounded-lg p-2.5 bg-white border lg:border-none border-gray-300 mb-2">
                            <Categories userId={userId} />
                        </div>


                    </div>
                </aside>


            </div>
            {/* Fixed button for small screens */}
            <div
                className="lg:hidden fixed bottom-0 left-0 right-0   bg-[#E3EDF7] border-t border-gray-300 shadow-lg p-2">
                <div className="flex justify-center">
                    <button
                        className="w-full bg-[#1681FF] p-2 border-1 rounded-md text-white font-bold text-ms">Repasser le
                        test
                    </button>
                </div>
            </div>

        </LayoutNo>
    );
}