import React, { useState, useEffect } from "react";
import SmallImg from "../images/handheart.svg";
import SavedMetiersCard from "../utils/SavedMetiersCard";
import { ItemsService } from "../services/ items.service";
import { CryptoService } from "../services/crypte.service";
import LayoutNo from "../utils/LayoutNo";


export default function SavedItems() {
    const [activeTab, setActiveTab] = useState(1);
    const [metiers, setMetiers] = useState([]);
    const [formations, setFormations] = useState([]);
    const decryptedUserId = CryptoService.decryptData(localStorage.getItem('user_id'))
    const [userId] = useState(() => decryptedUserId);

    // const { userId } = useParams();
    // const decodedEncryptedUserId = decodeURIComponent(userId);
    // const decryptedUserId = CryptoService.decryptData(decodedEncryptedUserId);

    useEffect(() => {
        fetchSavedProfessions();
    }, [userId]);

    const fetchSavedProfessions = async () => {
        if (decryptedUserId) {
            try {
                const response = await ItemsService.getSavedProfessions(decryptedUserId);
                if (response.data && response.data.status === "success") {
                    setMetiers(response.data.data);
                } else {
                    console.error(response.message);
                }
            } catch (error) {
                console.error('Error fetching saved professions:', error);
            }
        }
    };

    const handleDeleteMetier = async (professionId) => {
        if (!decryptedUserId) {
            return;
        }
        try {
            await ItemsService.deleteProfession(decryptedUserId, professionId);
            setMetiers(metiers.filter(metier => metier.profession_id !== professionId));
        } catch (error) {
            console.error('Error deleting profession:', error);
        }
    };

    const handleDeleteFormation = async (formationId) => {
        if (!decryptedUserId) {
            return;
        }
        try {
            // setFormations(formations.filter(formation => formation.id !== formationId));
        } catch (error) {
            console.error('Error deleting formation:', error);
        }
    };


    const goToNewPage = (professionId) => () => {
        window.open(`/details/${professionId}`, '_blank', 'noopener,noreferrer');
    };

    return (
        <LayoutNo>
            <div className="w-full md:mt-10 mt-12">
                <div>
                    <p className="font-bold text-2xl p-2">Éléments sauvegardés</p>
                    <div className="p-2 mt-2">
                        <div className="flex border-bottom-2 border-gray-200">
                            <button
                                className={`py-2 px-4 text-sm font-medium ${activeTab === 1 ? 'border-2 border-gray-300 border-b-0 text-blue-600 rounded-t-lg' : 'text-gray-600'} focus:outline-none`}
                                onClick={() => setActiveTab(1)}
                            >
                                Métiers
                            </button>
                            <button
                                className={`py-2 px-4 text-sm font-medium ${activeTab === 2 ? 'border-2 border-gray-300 border-b-0 text-blue-600 rounded-t-lg' : 'text-gray-600'} focus:outline-none`}
                                onClick={() => setActiveTab(2)}
                            >
                                Formations
                            </button>
                        </div>
                        <div className="mt-1">
                            {activeTab === 1 && (
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 mt-2 p-2">
                                    {metiers.map((metier) => (
                                        <SavedMetiersCard
                                            key={metier.profession_id}
                                            imgSrc={metier.pictact || SmallImg}
                                            smtitle={metier.business_sect || ''}
                                            title={metier.profession || ''}
                                            chips={metier.chips || []}
                                            frompriceDebutant={metier.salary_1 || ''}
                                            topriceDebutant={metier.salary_2 || ''}
                                            frompriceExp={metier.salary_3 || ''}
                                            topriceExp={metier.salary_4 || ''}
                                            navigateTo={goToNewPage(metier.profession_id)}

                                            onDelete={() => handleDeleteMetier(metier.profession_id)}
                                        /*
                                        key={metier.profession_id}
                                              imgSrc={SmallImg}
                                              smtitle={metier.business_sect}
                                              title={metier.profession}
                                              chips={["Bac+5", "Bac+3"]}
                                              frompriceDebutant={metier.salary_1}
                                              topriceDebutant={metier.salary_2}
                                              frompriceExp={metier.salary_3}
                                              topriceExp={metier.salary_4}
                                              professionId={metier.profession_id}
                                              saveProfession={handleSaveProfession}
                                              deleteProfession={handleDeleteProfession}
                                        */
                                        />
                                    ))}
                                </div>
                            )}
                            {activeTab === 2 && (
                                <div className=" mt-2 p-2">
                                    {/* {formations.length && (
                                        formations.map((formation) => (
                                            <SavedFormationCard
                                                key={formation.id}
                                                imgSrc={formation.imgSrc}
                                                smtitle={formation.smtitle}
                                                title={formation.title}
                                                chips={formation.chips}
                                                onDelete={() => handleDeleteFormation(formation.id)}
                                            />
                                        ))
                                    )} */}
                                    <p className="font-poppins flex justify-center text-[20px] font-medium leading-[17.5px] text-gray-800">
                                        Bientôt disponible
                                    </p>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </LayoutNo>
    );
}