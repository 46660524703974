// import React, { useEffect, useState } from 'react';
// import SingleCard from './cards/SingleCard';
// import { UserService } from '../../services/userforms.service';
//
// export default function NiveauActuel({ previousSelection, selectedData, setSelectedData }) {
//     const [types, setTypes] = useState([]);
//     const [loading, setLoading] = useState(true);
//     const [error, setError] = useState(null);
//     const [title, setTitle] = useState("");
//     const [description, setDescription] = useState("");
//
//     useEffect(() => {
//         // Update index and graduate based on previous selection
//         console.log("previousSelection", previousSelection);
//         let updatedIndex = 0;
//         let updatedGraduate = 0;
//         switch (previousSelection) {
//             case 1:
//                 updatedIndex = 1;
//                 updatedGraduate = 0;
//                 setTitle("Quel est votre niveau d’études ?");
//                 setDescription("Nous vous accompagnons grâce à des conseils personnalisés tout au long de votre parcours");
//                 break;
//             case 2:
//                 updatedIndex = 2;
//                 updatedGraduate = 0;
//                 setTitle(" En quelle année êtes vous actuellement ?");
//                 setDescription("Nous ferons de notre mieux pour vous proposer les formations qui correspondent à votre projet");
//                 break;
//             case 3:
//             case 4:
//                 updatedIndex = 0;
//                 updatedGraduate = 1;
//                 setTitle("Quel est le dernier niveau d’études que vous avez validé ?");
//                 setDescription("Nous vous accompagnons grâce à des conseils personnalisés tout au long de votre parcours");
//                 break;
//             default:
//                 break;
//         }
//
//         fetchData(updatedIndex, updatedGraduate);
//     }, [previousSelection]); // Only run when previousSelection changes
//
//     const fetchData = async (index, graduate) => {
//         setLoading(true);
//         setError(null);
//         try {
//             const response = await UserService.getStudiesLevel(index, graduate);
//             console.log("getStudiesLevel", index, graduate);
//             if (response.data.status === 'success') {
//                 const fetchedTypes = response.data.data.map((item) => ({
//                     id: item.studlevel_id,
//                     value: item.study_level,
//                     label: item.study_level,
//                     study_graduate: item.study_graduate,
//                     study_status: item.study_status,
//                 }));
//                 setTypes(fetchedTypes);
//             } else {
//                 setError(response.data.message || 'An error occurred');
//             }
//         } catch (err) {
//             setError(err.message || 'An error occurred');
//         } finally {
//             setLoading(false);
//         }
//     };
//
//     const handleChange = (event) => {
//         setSelectedData(parseInt(event.target.id));
//     };
//
//     if (loading) {
//         return (
//             <div className="mt-5">
//                 <p className="font-poppins text-2xl mb-1">{title}</p>
//                 <p className="font-inter text-[16px] font-normal leading-[25.6px] text-left mt-2 mb-2">{description}</p>
//                 <div>Loading...</div>
//             </div>
//         );
//     }
//
//     if (error) {
//         return <div>Error: {error}</div>;
//     }
//
//     return (
//         <div className="mt-5">
//             <p className="font-poppins text-2xl mb-1">{title}</p>
//             <p className="font-inter text-[16px] font-normal leading-[25.6px] text-left mt-2 mb-2">{description}</p>
//             {/* <div className="grid grid-vol-1 md:grid-cols-2 gap-1 py-5 px-2"> */}
//             <div className={`grid grid-vol-1 md:grid-cols-2 gap-x-4 gap-y-2 px-2 mt-1`}>
//                 {types.map((option) => (
//                     <SingleCard
//                         id={option.id}
//                         key={option.id}
//                         value={option.value}
//                         label={option.label}
//                         checked={selectedData === option.id}
//                         onChange={handleChange}
//                     />
//                 ))}
//             </div>
//         </div>
//     );
// }
import React, { useEffect, useState } from 'react';
import SingleCard from './cards/SingleCard';
import { UserService } from '../../services/userforms.service';
import CircularProgress from "@mui/material/CircularProgress";

export default function NiveauActuel({ previousSelection, selectedData, setSelectedData }) {
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [currentStep, setCurrentStep] = useState(0); // For handling pagination steps
    const itemsPerPage = 6; // Items per page

    useEffect(() => {
        let updatedIndex = 0;
        let updatedGraduate = 0;
        switch (previousSelection) {
            case 1:
                updatedIndex = 1;
                updatedGraduate = 0;
                setTitle("Quel est votre niveau d’études ?");
                setDescription("Nous vous accompagnons grâce à des conseils personnalisés tout au long de votre parcours");
                break;
            case 2:
                updatedIndex = 2;
                updatedGraduate = 0;
                setTitle("En quelle année êtes-vous actuellement ?");
                setDescription("Nous ferons de notre mieux pour vous proposer les formations qui correspondent à votre projet");
                break;
            case 3:
            case 4:
                updatedIndex = 0;
                updatedGraduate = 1;
                setTitle("Quel est le dernier niveau d’études que vous avez validé ?");
                setDescription("Nous vous accompagnons grâce à des conseils personnalisés tout au long de votre parcours");
                break;
            default:
                break;
        }

        fetchData(updatedIndex, updatedGraduate);
    }, [previousSelection]);

    const fetchData = async (index, graduate) => {
        setLoading(true);
        setError(null);
        try {
            const response = await UserService.getStudiesLevel(index, graduate);
            if (response.data.status === 'success') {
                const fetchedTypes = response.data.data.map((item) => ({
                    id: item.studlevel_id,
                    value: item.study_level,
                    label: item.study_level,
                }));
                setTypes(fetchedTypes);
            } else {
                setError(response.data.message || 'An error occurred');
            }
        } catch (err) {
            setError(err.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        setSelectedData(parseInt(event.target.id));
    };

    const handleNext = () => {
        if (currentStep < Math.ceil(types.length / itemsPerPage) - 1) {
            setCurrentStep((prevStep) => prevStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            setCurrentStep((prevStep) => prevStep - 1);
        }
    };

    const currentTypes = types.slice(currentStep * itemsPerPage, (currentStep + 1) * itemsPerPage);

    if (loading) {
        return(
            <div className="flex justify-center mt-5">
                <CircularProgress />
            </div>
        )
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="mt-5 md:px-0">
            <p className="font-poppins text-2xl mb-1">{title}</p>
            <p className="font-inter text-[16px] font-normal leading-[25.6px] text-left mt-2 mb-2">{description}</p>
            <div className=" relative flex w-full justify-center py-3">
                {currentStep > 0 && (
                    <button
                        onClick={handlePrev}
                        className="absolute -left-1 md:top-[150px] transform -translate-y-1/2 top-1/2 px-2 py-1 border-2 border-black bg-gray-100 rounded-full text-lg md:text-xl"
                    >
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L1 6L6 11" stroke="#141943" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                )}
                <div className="grid w-full pl-2 justify-center grid-cols-1 md:grid-cols-2 gap-1 mx-4 md:mx-1">
                    {currentTypes.map((option) => (
                        <SingleCard
                            id={option.id}
                            key={option.id}
                            value={option.value}
                            label={option.value}
                            checked={selectedData === option.id}
                            onChange={handleChange}
                        />
                    ))}
                </div>
                {currentStep < Math.ceil(types.length / itemsPerPage) - 1 && (
                    <button
                        onClick={handleNext}
                        className="absolute -right-1 transform -translate-y-1/2 top-1/2 px-2 py-1 border-2 border-black bg-gray-100 rounded-full text-lg md:text-xl"
                    >
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L6 6L1 11" stroke="#141943" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                        </svg>
                    </button>
                )}
            </div>
        </div>
    );
}
