import React, { useEffect, useState } from 'react';
import { UserService } from '../../services/userforms.service';
import SingleCard from './cards/SingleCard';
import CircularProgress from '@mui/material/CircularProgress';

export default function SituationStep({ selectedData, setSelectedData }) {
    const [types, setTypes] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await UserService.getUserStatus();
            if (response.data.status === 'success') {
                const fetchedTypes = response.data.data.map((item) => ({
                    id: item.status_id,
                    value: item.status_name,
                    label: item.status_name
                }));
                setTypes(fetchedTypes);
            } else {
                setError(response.data.message || 'An error occurred');
            }
        } catch (err) {
            setError(err.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };

    const handleChange = (event) => {
        const selectedId = parseInt(event.target.id, 10); // Convertir en nombre
        setSelectedData(selectedId);
        console.log("Selected selectedId:", selectedId);
    };


    if (loading) {
        return(
            <div className="flex justify-center mt-5">
                <CircularProgress />
            </div>
        )
    }

    if (error) {
        return (
            <div>
                <p>Error: {error}</p>
                <button onClick={fetchData} className="py-2 px-4 bg-blue-500 text-white rounded">Retry</button>
            </div>
        );
    }

    return (
        <div className="mt-5">
            <p className="font-inter text-2xl mb-1">👋 Quel est votre statut ?</p>
            <p className="font-inter text-[16px] font-normal leading-[25.6px] text-left mt-2 mb-2">Des recommandations vous sont proposées en fonction de votre situation</p>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-1 py-5 px-2">
                {types.map((option) => (
                    <SingleCard
                        id={option.id}
                        key={option.id}
                        value={option.value}
                        label={option.label}
                        checked={selectedData == option.id}
                        onChange={handleChange}
                    />
                ))}
            </div>
        </div>
    );
}
