import LayoutNo from "../utils/LayoutNo";
import Igikai from "../images/igikai.svg";
import Handicon from "../images/landingpage/handicon";
import React, { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom';
import { UserService } from "../services/userforms.service";
import { CryptoService } from "../services/crypte.service";

export default function QuiCeQueIkegai() {
    const [userCalculate, setUserCalculate] = useState();
    const [viewResult, setViewResult] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        document.body.classList.add("overflow-y-hidden");
        fetchData();

        return () => {
          document.body.classList.remove("overflow-y-hidden");
        };
    }, []);

    const fetchData = async () => {
        try {
            const userId = localStorage.getItem('user_id');
            const decryptedId = CryptoService.decryptData(userId)
            const response = await UserService.getUserCalculate(decryptedId);
    
            if (Array.isArray(response.data) && response.data.data.length === 0) {
                setViewResult(false);
            } else {
                setUserCalculate(response.data.data);
                setViewResult(true);
            }
    
        } catch (error) {
            console.error("Error fetching user calculate:", error);
            setViewResult(false);
        }
    };
    

    const handleTestButtonClick = () => {
        navigate('/public');
    };

    const handleResultsButtonClick = () => {
        navigate('/guidance');
    };

    return (
        <LayoutNo>
            <div className="flex flex-col md:flex-row mt-2 h-screen">
                {/* Left Section - Scrollable */}
                <div className="w-full md:w-1/2 p-4 bg-white border-2 border-gray-300 overflow-y-auto">
                    <h2 className="text-xl font-poppins mb-2">Qu’est-ce que l’IKIGAI ?</h2>
                    <p className="font-inter text-sm text-gray-700">
                        L'ikigai est un concept japonais qui se réfère à la raison de vivre, à ce qui donne un sens à notre existence, à ce qui nous motive et nous épanouit profondément. Le terme "ikigai" est composé de deux mots japonais : "iki", qui signifie "vivre" ou "existence", et "gai", qui signifie "raison" ou "valeur". Ainsi, l'ikigai peut être compris comme "la raison de vivre" ou "la raison d'être".
                    </p>
                    <p className="font-inter text-sm text-gray-700 mt-2">
                        Selon la philosophie de l'ikigai, chaque personne possède un ikigai unique qui combine quatre éléments essentiels :
                    </p>
                    <h3 className="text-md font-poppins mb-2">Ce que vous aimez</h3>
                    <p className="font-inter text-sm text-gray-700">Ce sont les activités, les intérêts ou les hobbies qui vous passionnent et vous apportent de la joie.</p>
                    <h3 className="text-md font-poppins mb-2">Ce dans quoi vous êtes doué(e)</h3>
                    <p className="font-inter text-sm text-gray-700">Ce sont vos compétences, vos talents naturels ou acquis, et les activités dans lesquelles vous excellez.</p>
                    <h3 className="text-md font-poppins mb-2">Ce dont le monde a besoin</h3>
                    <p className="font-inter text-sm text-gray-700">Il s'agit des besoins de la société ou de votre communauté auxquels vous pouvez contribuer positivement.</p>
                    <h3 className="text-md font-poppins mb-2">Ce pour quoi vous pouvez être rémunéré(e)</h3>
                    <p className="font-inter text-sm text-gray-700">Ce sont les activités ou les métiers pour lesquels vous pouvez être rémunéré(e) et qui vous permettent de subvenir à vos besoins financiers.</p>
                    <p className="font-inter text-sm text-gray-700 mt-4">
                        L'ikigai se situe à l'intersection de ces quatre éléments. Lorsque vous trouvez cette intersection, vous êtes en harmonie avec vous-même et avec le monde qui vous entoure, ce qui peut conduire à une vie pleine de sens, de satisfaction et de bonheur.
                    </p>
                </div>

                {/* Right Section - Fixed */}
                <div className="w-full md:w-1/2 p-4 lg:mb-8 flex flex-col items-center justify-center sticky top-0 bg-white">
                    <img src={Igikai} alt="Description of image" className="object-cover w-4/5" />
                    <div className="flex flex-col md:flex-row w-full space-y-2 md:space-y-0 md:space-x-4 mt-4">
                        <button
                            onClick={handleTestButtonClick}
                            className="bg-[#1681FF] w-full md:w-1/2 text-white font-bold py-2 px-6 rounded-md flex items-center justify-center space-x-2">
                            <span>{viewResult ? 'Repassez le test' : 'Passez le test' }</span>
                            <Handicon />
                        </button>
                        {viewResult && (
                            <button
                                onClick={handleResultsButtonClick}
                                className="bg-transparent text-[#1681FF] w-full md:w-1/2 font-bold py-2 px-6 rounded-md flex items-center justify-center space-x-2 border-2 border-blue-500">
                                Voir mes résultats
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </LayoutNo>
    );
}