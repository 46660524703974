const SearchIcon = () => {
    return (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.15625 14.0625C11.4182 14.0625 14.0625 11.4182 14.0625 8.15625C14.0625 4.89432 11.4182 2.25 8.15625 2.25C4.89432 2.25 2.25 4.89432 2.25 8.15625C2.25 11.4182 4.89432 14.0625 8.15625 14.0625Z" stroke="#1681FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M12.332 12.3329L15.7493 15.7501" stroke="#1681FF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>



    )
}
export default SearchIcon;