import React, { useState } from 'react';
import GoogleIcon from "../../images/google.svg";
import AppleIcon from "../../images/apple.svg";
import EyeHide from "../../images/eye-hide.svg";
import EyeShow from "../../images/eye-view.svg";
import { VerificationModal } from '../modal/auth/VerificationModal';
import { GoogleLogin } from '@react-oauth/google';
import { useAuth } from '../../services/auth.provider';
import { AuthService } from '../../services/auth.service';
import warningIcon from '../../images/modal/warning.svg';
import ConditionsGeneralesDialog from "../../utils/ConditionsGenerales";

export default function SignUpStep({ onSubmit }) {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showOtpModal, setShowOtpModal] = useState(false);
    const { googleSignUp, signUp } = useAuth();
    const [alreadyExist, setAlreadyExist] = useState(false);
    const [emptyValue, setEmptyValue] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openConditionsDialog, setOpenConditionsDialog] = useState(false);


    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setAlreadyExist(false);
        setEmptyValue(false);
    
        // Check for empty values
        if (!email || !password) {
            console.log("data empty !");
            setIsLoading(false);
            setEmptyValue(true);
            return;
        }
    
        try {
            let lastEmail = localStorage.getItem('email-signup');
            let response;
    
            if (lastEmail) {
                response = await AuthService.checkEmail(email, lastEmail, 1);
            } else {
                response = await AuthService.checkEmail(email, null, 0);
            }
    
            if (response.data.status === 'success') {
                setShowOtpModal(true);
                setAlreadyExist(false);
                setEmptyValue(false);
            } else {
                setAlreadyExist(true);
                setEmptyValue(false);
                console.error(response.data.message, 'An error occurred');
            }
        } catch (error) {
            setAlreadyExist(true);
            console.error('Signup failed:', error);
        } finally {
            // Ensure loading is stopped in all cases
            setIsLoading(false);
        }
    };
    
    
    const handleGoogleSuccess = async (response) => {
        const token = response.credential;
        try {
            console.log(response);
            await googleSignUp(token);
        } catch (error) {
            console.error('Google login failed:', error);
        }
    };

    const handleGoogleFailure = (error) => {
        console.error(error);
    };

    return (
        <div className="flex justify-center items-center mt-5">
            <div className="max-w-md w-full bg-white">
                <h1 className="font-poppins text-[28px] text-center text-[#141943] mb-1">Dernière étape</h1>
                <p className="text-[16px] text-center text-[#434769] mb-3">Votre avenir commence ici !</p>
                <div className="space-y-4">
                {emptyValue && (
                    <div className="bg-red-50 text-red-500 px-4 py-1 rounded-md flex items-center mb-2">
                        <img src={warningIcon} alt="Warning" className="mr-2" />
                        <span>Veuillez remplir tous les champs</span>
                    </div>
                )}

                    {alreadyExist && (
                        <div className="bg-red-50 text-red-500 px-2 py-2 rounded-md flex items-center mb-2">
                            <img src={warningIcon} alt="Warning" className="mr-2" />
                            <span>L'adresse e-mail que vous avez saisie existe déjà. Veuillez en utiliser une autre.</span>
                        </div>
                    )}

                    <div className="flex flex-col">
                        <label className="block text-[12px] font-inter text-[#141943E5] mb-1">E-mail</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Texte"
                            className="w-full px-3 py-2 border border-gray-300 bg-[#F2F2F2] rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-300"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-[12px] font-inter text-[#141943E5] mb-1">Mot de passe</label>
                        <div className="relative w-full">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="********"
                                className="w-full px-3 py-2 border border-gray-300 bg-[#F2F2F2] rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-300 pr-10"
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <img src={EyeShow} alt="Afficher le mot de passe" className="h-6 w-6" />
                                ) : (
                                    <img src={EyeHide} alt="Masquer le mot de passe" className="h-6 w-6" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <p className="text-xs text-gray-500 mt-3 text-center">
                    En cliquant sur S'inscrire ou sur Se connecter, vous acceptez les <a onClick={() => setOpenConditionsDialog(true)} className="text-blue-500 cursor-pointer">CGU</a> de Linkyways.
                </p>
                <button
                    onClick={handleSubmit}
                    className="w-full mt-2 py-2 px-4 bg-[#1681FF] text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >
                    {isLoading ? 'Chargement...' : 'S\'inscrire'}
                </button>
                <div className="flex items-center my-3">
                    <div className="flex-grow border-t border-gray-300"></div>
                    <span className="mx-4 text-gray-400 font-bold text-sm">ou</span>
                    <div className="flex-grow border-t border-gray-300"></div>
                </div>
                <div className="space-y-2 flex justify-center">
                    <GoogleLogin
                    onSuccess={handleGoogleSuccess}
                    onError={handleGoogleFailure}
                    render={(renderProps) => (
                        <button
                            onClick={handleGoogleSuccess}
                            disabled={renderProps.disabled}
                            className="flex justify-center items-center gap-2 py-2 px-4 text-sm font-medium rounded-full border border-gray-300 bg-white text-black hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition ease-in-out w-full"
                        >
                            <img src={GoogleIcon} alt="Google" className="h-5" />
                            <span>Continuer avec Gmail</span>
                        </button>
                    )}
                />
                    {/*<button*/}
                    {/*    type="button"*/}
                    {/*    className="w-full flex items-center justify-center gap-2 py-2 px-4 text-sm font-medium rounded-full border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"*/}
                    {/*>*/}
                    {/*    <img src={AppleIcon} alt="Apple" />*/}
                    {/*    <span>Continuer avec Apple</span>*/}
                    {/*</button>*/}
                    <VerificationModal email={email} password={password} visible={showOtpModal} onHide={() => setShowOtpModal(false)} /> {/* onOtpVerified={handleOtpVerification} */}
                    <ConditionsGeneralesDialog open={openConditionsDialog} onClose={() => setOpenConditionsDialog(false)} />

                </div>
            </div>
        </div>
    );
}
