import React, { useState } from 'react';
import CustomRadio from '../../utils/CustomRadio';

export default function StartFormationStep({ selectedData, setSelectedData }) {

    const handleChange = (event) => {
        console.log("setSelectedData StartFormation ", event.target.value);
        
        setSelectedData(event.target.value);
    };

    const radioOptions = [
        { value: '2024', label: '2024 ' },
        { value: '2025', label: '2025 ' },
        { value: '2026', label: '2026' },
    ];

    return (

        <div className="mt-5">
            <p className="font-poppins text-2xl mb-1">En quelle année souhaitez-vous commencer votre formation ?</p>
            <p className="font-inter text-[16px] font-normal leading-[25.6px] text-left mt-2 mb-2">Nous ferons de notre mieux pour vous proposer les formations qui correspondent à votre projet</p>
            <div className="flex flex-col  justify-center  py-2 ">

                {radioOptions.map((option) => (
                    <CustomRadio
                        key={option.value}
                        value={option.value}
                        label={option.label}
                        checked={selectedData === option.value}
                        onChange={handleChange}
                    />
                ))}
            </div>
        </div>
    );
}
