import Axios from './caller.service';

/**
 * Récupération de la liste des utilisateurs
 * @returns {Promise}
 */


let getIKISubjects = (subject_search = null, category = null) => {
    let params = {};
    
    if (subject_search !== null) {
        params.subject_search = subject_search;
    }

    if (category !== null) {
        params.category = category;
    }
    
    return Axios.get('/IKI_subjects/', { params: params });
}

let addQuestions = async (userId, responses, subjectsLevel) => {
    const requestBody = {
        user_id: userId,
        questions_scores: responses.map(response => ({
            question_id: response.questionId,
            score: response.score
        })),
        subjects_level: subjectsLevel
    };

    try {
        const response_1 = await Axios.post('/UserSubmission/', requestBody);
        console.log('Data submitted successfully:', response_1.data);
    } catch (error) {
        console.error('Error submitting data:', error);
    }
};

export const GuidancesService = {
    getIKISubjects, addQuestions
}
