import React, { useEffect, useState } from "react";
import LayoutNo from "../utils/LayoutNo";
import Bgimg from "../images/datailspage.png";
import ExampleImage from "../images/entreprise.svg";
import Star from "../images/star.svg";
import GradientBarWithArrow from "../utils/GradiantBarWithArrow";
import tooltipSvg from "../images/search/tooltipSvg.svg";
import HtmlTooltip from "../utils/HtmlTooltip";
import { useNavigate, useParams } from "react-router-dom";
import { MetierService } from "../services/metier.service";
import HeartIcon from '../images/heart.svg';
import FilledHeartIcon from '../images/heart-filled.svg';
import { ItemsService } from "../services/ items.service";
import LoginModal from "../components/modal/auth/LoginModal";
import { CryptoService } from "../services/crypte.service";
import CircularProgress from "@mui/material/CircularProgress";

export default function MetierDetailPage() {
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [profession, setProfession] = useState(null);
    const [details, setDetails] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    let { professionId } = useParams();
    const [isSaved, setIsSaved] = useState(0);
    const [isOpen, setIsOpen] = useState(false);
    const [userId, setUserId] = useState('');
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    // Detect screen size
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        handleResize(); // Set initial value
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const toggleTooltip = () => {
        setTooltipVisible(!tooltipVisible);
    };



    useEffect(() => {
        const user = localStorage.getItem('user_id');
        if (user) {
            const userId = CryptoService.decryptData(user)
            setUserId(userId)
        }

        fetchData();
    }, [professionId ,userId]);

    const fetchData = async () => {
        try {
            console.log("professionId", professionId)

            const searchProfessionsResponse = await MetierService.searchProfessions(null, null, null, null, null, professionId ,userId);
            const descriptionResponse = await MetierService.get35aProfessionsDescription(professionId)

            if (descriptionResponse.data.status === "success") {
                const fetchedData = descriptionResponse.data.data[0];
                setDetails({
                    profession: fetchedData.profession,
                    educationExperience: fetchedData.exigences_en_education_et_expérience,
                    professionId: fetchedData.profession_id,
                    careerProgression: fetchedData.perspectives_devolution,
                    workEnvironment: fetchedData.environnement_de_travail,
                    description: fetchedData.description,
                    dailyTasks: fetchedData.tâches_quotidiennes,
                    futureProspects: fetchedData.avenir,
                    image1: fetchedData.image1,
                    image2: fetchedData.image2,

                })


            } else {
                setError(descriptionResponse.data.message || "An error occurred while fetching profession details.");
            }

            if (searchProfessionsResponse.data.status === "success") {
                const professionData = searchProfessionsResponse.data.data[0];
                setProfession({
                    professionId: professionData.profession_id,
                    profession: professionData.profession,
                    internalLink: professionData.profession_internal_link,
                    studyLevel: professionData.study_level,
                    businessSector: professionData.business_sect,
                    salary_1: professionData.salary_1,
                    salary_2: professionData.salary_2,
                    salary_3: professionData.salary_3,
                    salary_4: professionData.salary_4,
                    skills: professionData.skills.split(","),
                    employability: professionData.employability,
                    employabilityComment: professionData.employability_comment,
                    pictact: professionData.pictact,
                    requiredStudyLevel: professionData.required_stud_level,
                    saved:professionData.saved,
                });
                setIsSaved(professionData.saved);
                console.log("saveddd", professionData.saved)
            } else {
                setError(searchProfessionsResponse.data.message || "An error occurred while fetching profession details.");
            }
        } catch (err) {
            setError(err.message || "An error occurred while fetching data.");
        } finally {
            setLoading(false);
        }
        console.log(details.description, "details.description")
    };

    if (loading) {
        return(
            <div className="flex justify-center mt-5">
                <CircularProgress />
            </div>
        )
    }

    if (error) {
        return <div>{error}</div>;
    }


    const handleHeartClick = async () => {
        if (isSaved) {
            // Remove from saved professions
            try {
                await handleDeleteProfession(professionId);
                setIsSaved(false);
            } catch (error) {
                console.error('Error deleting profession:', error);
            }
        } else {
            // Save profession
            try {
                await handleSaveProfession(professionId);
                setIsSaved(true);
            } catch (error) {
                console.error('Error saving profession:', error);
            }
        }
    };

    const showModal = () => setIsModalVisible(true);
    const hideModal = () => setIsModalVisible(false);

    const handleSaveProfession = async (professionId) => {
        if (!userId) {
            showModal();
            return;
        }
        try {
            await ItemsService.saveProfession(userId, professionId);
        } catch (error) {
            console.error('Error saving profession:', error);
        }
    };

    const handleDeleteProfession = async (professionId) => {
        if (!userId) {
            showModal();
            return;
        }
        try {
            await ItemsService.deleteProfession(userId, professionId);
        } catch (error) {
            console.error('Error deleting profession:', error);
        }
    };
    console.log("image2 ", details.image2)


    return (
        <LayoutNo>
            <div className="relative mt-1">
                {/* <button className="p-1 bg-white shadow-md border-1 border-gray-100 rounded-md">
                    Retour
                </button> */}
                <div
                    className="mt-6 md:mt-2 w-full h-48 rounded-xl bg-cover bg-center"
                    style={{ backgroundImage: `url(${details.image2.replace(/ /, '%20')})` }}
                >
                </div>

                <div className="flex flex-col md:flex-row gap-2 mt-2">

                    {/* Save Button */}

                    {/* First Card - 80% of the screen */}
                    <div className="flex-grow md:w-9/12 bg-white shadow-lg rounded-lg p-2">
                        <div className="flex justify-between mb-2">
                            <button className=" md:hidden justify-start p-2 bg-white  rounded-md flex items-center" onClick={handleHeartClick}>

                                    <img
                                        src={isSaved ? FilledHeartIcon : HeartIcon}
                                        alt={isSaved ? "remove from favorites" : "add to favorites"}
                                        className="w-6 h-6 -mt-2 mr-4"
                                    />
                            </button>
                            <button
                                className="md:hidden flex gap-2 justify-end px-4 py-2 bg-[#1681FF] text-white rounded-xl"
                                onClick={() => setPopupOpen(true)}
                            >
                                Détails <svg width="22" height="24" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M10 17.5C14.1421 17.5 17.5 14.1421 17.5 10C17.5 5.85786 14.1421 2.5 10 2.5C5.85786 2.5 2.5 5.85786 2.5 10C2.5 14.1421 5.85786 17.5 10 17.5Z" stroke="white" strokeWidth="1.5" strokeMiterlimit="10" />
                                    <path d="M10.4727 12.6519L13.1243 10.0003L10.4727 7.34863" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                    <path d="M6.875 10H13.125" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                                </svg>

                            </button>
                        </div>

                        <div className="flex justify-between mb-1">
                            <p className="justify-start font-poppins font-semibold text-[#141943] text-2xl">
                                {profession.profession}
                            </p>
                            <button className="invisible md:visible flex items-center justify-end gap-2  md:flex"
                             onClick={handleHeartClick}>
                        <img
                            src={isSaved ? FilledHeartIcon : HeartIcon}
                            alt={isSaved ? "remove from favorites" : "add to favorites"}
                           className="w-6 h-6 -mt-2 mr-4"
                        />
                    </button>
                        </div>

                        <div className="mt-3 gap-2">
                            <p className="font-poppins font-bold text-[#141943] text-[18px]">

                                Description
                            </p>
                            <p className="font-inter text-[#141943] text-base">{details.description}</p>
                        </div>
                        <div className="mt-3 gap-2">
                            <p className="font-poppins font-bold text-[#141943] text-[18px]">
                                Environnement de Travail
                            </p>
                            <p className="font-inter text-[#141943] text-base">{details.workEnvironment}</p>
                        </div>
                        <div className="mt-3 gap-2">
                            <p className="font-poppins font-semibold text-[#141943] text-lg">
                                Éducation et Expérience
                            </p>
                            <p className="font-inter text-[#141943] text-base">{details.educationExperience}</p>
                        </div>
                        {/* <div className="mt-3">
                            <p className="font-poppins text-sm font-bold">Tâches Quotidiennes </p>
                            <div className="flex items-start ">
                                <span className="text-xl font-bold text-gray-800">•</span>
                                <p className="ml-2 text-gray-700 mt-1">
                                    Développer des systèmes robotiques pour l'exploration spatiale
                                </p>
                            </div>
                            <div className="flex items-start ">
                                <span className="text-xl font-bold text-gray-800">•</span>
                                <p className="ml-2 text-gray-700 mt-1">
                                    Tester et valider les robots
                                </p>
                            </div>
                        </div> */}
                        <div className="mt-3">
                            <p className="font-poppins font-semibold text-[#141943] text-lg">Tâches Quotidiennes </p>
                            {details.dailyTasks.split(";").map((task, index) => (
                                <div className="flex items-start " key={index}>
                                    <span className="font-inter text-[#141943] text-lg -mt-2">•</span>
                                    <p className="ml-2 font-inter text-[#141943] text-base -mt-2 ">{task}</p>
                                </div>
                            ))}
                        </div>

                        <div className="mt-3 gap-2">
                            <p className="font-poppins font-semibold text-[#141943] text-lg">
                                Perspectives d'Évolution
                            </p>
                            <p className="font-inter font-normal text-[#141943] text-base">
                                {details.careerProgression}
                            </p>
                        </div>

                        {/*<div className="mt-3">*/}
                        {/*    /!* Title and Description *!/*/}
                        {/*    <div className="mb-4">*/}
                        {/*        <p className="font-poppins font-semibold text-[#141943] text-lg">*/}
                        {/*            Entreprises qui recrutent*/}
                        {/*        </p>*/}
                        {/*        <p className="font-inter text-[#141943] text-base">*/}
                        {/*        </p>*/}
                        {/*    </div>*/}

                        {/*    /!* Images Container *!/*/}
                        {/*    <div className="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 p-2">*/}
                        {/*        /!* Image Example *!/*/}
                        {/*        <img*/}
                        {/*            src={ExampleImage}*/}
                        {/*            alt="Example 1"*/}
                        {/*            className="rounded-lg object-cover border-2 border-gray-200 p-1"*/}
                        {/*        />*/}
                        {/*        <img*/}
                        {/*            src={ExampleImage}*/}
                        {/*            alt="Example 1"*/}
                        {/*            className="rounded-lg object-cover border-2 border-gray-200 p-1"*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                    </div>

                    {/* Second Card - 20% of the screen */}
                    <div className="md:w-3/12 bg-white shadow-lg rounded-lg p-2 hidden md:block">
                        <p className="font-poppins font-bold text-sm">Salaires</p>
                        <div className="flex justify-between items-center mt-2">
                            <p className="text-xs font-inter font-medium">Débutant :</p>
                            <p className="text-xs font-medium text-gray-600">{profession.salary_1}€ - {profession.salary_2}€</p>
                        </div>
                        <div className="flex justify-between items-center mt-2">
                            <p className="text-xs font-medium">Expérimenté :</p>
                            <p className="text-xs font-medium text-gray-600">{profession.salary_3}€ - {profession.salary_4}€</p>
                        </div>

                        <hr className="my-3" />
                        <div>
                            <p className="font-poppins font-bold text-sm">Niveau requis</p>
                            <span className="bg-gray-200 text-gray-700 text-xs font-semibold px-2 py-1 rounded-full">{profession.requiredStudyLevel}</span>
                        </div>
                        <hr className="my-3" />
                        <div>
                            <p className="font-poppins font-bold text-sm">Métier d'avenir</p>
                            <GradientBarWithArrow value={details.futureProspects} />
                        </div>
                        <hr className="my-3" />
                        <div>
                            <div className="flex items-center">
                                <h1 className="font-poppins font-bold text-sm">Employabilité</h1>
                                <HtmlTooltip
                                    title={
                                        <p className="text-sm leading-tight text-black">
                                            {profession.employabilityComment}
                                        </p>
                                    }
                                    placement="right"
                                >
                                    {/*<Button >*/}
                                    <img src={tooltipSvg} alt="Info"
                                        className="mb-2 object-cover h-[20px] ml-2 cursor-pointer" />
                                    {/*</Button>*/}
                                </HtmlTooltip>

                            </div>

                            <GradientBarWithArrow value={profession.employability} />
                        </div>
                        <hr className="my-3" />
                        <div className="flex justify-between">
                            <p className="font-poppins font-bold text-sm">Formations</p>
                            <p className="font-poppins text-gray-400 font-bold text-sm">Bientôt disponible</p>
                        </div>
                        <hr className="my-3" />
                    </div>
                </div>

                {/* Popup for smaller screens */}
                {isPopupOpen && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
                        <div className="bg-white  shadow-lg  w-full max-w-lg">
                            <div className="flex font-poppins font-bold justify-between border-2 p-2 shadow-md">
                                <p>
                                    Details
                                </p>
                                <button
                                    className=" text-black rounded-full"
                                    onClick={() => setPopupOpen(false)}
                                >
                                    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.22168 8.22168L23.778 23.778" stroke="#141943" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                        <path d="M8.22168 23.7783L23.778 8.22197" stroke="#141943" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>

                                </button>
                            </div>
                            <p className="font-poppins font-bold text-sm p-2">Salaires</p>
                            <div className="flex justify-between items-center mt-2 p-2">
                                <p className="text-xs font-medium">Débutant :</p>
                                <p className="text-xs font-medium text-gray-600">{profession.salary_1}€ - {profession.salary_2}€</p>
                            </div>
                            <div className="flex justify-between items-center mt-2 p-2">
                                <p className="text-xs font-medium">Expérimenté :</p>
                                <p className="text-xs font-medium text-gray-600">{profession.salary_3}€ - {profession.salary_4}€</p>
                            </div>

                            <hr className="my-3 p-2" />
                            <div className="p-2">
                                <p className="font-poppins font-bold text-sm">Niveau requis</p>
                                <span className="bg-gray-200 text-gray-700 text-xs font-semibold px-2 py-1 rounded-full uppercase">{profession.requiredStudyLevel}</span>
                            </div>
                            <hr className="my-3 p-2" />
                            <div className="p-2">
                                <p className="font-poppins font-bold text-sm">Métier d'avenir</p>
                                <GradientBarWithArrow value={details.futureProspects} />
                            </div>
                            <hr className="my-3 p-2" />
                            <div className="p-2">
                                <div className="flex">
                                <p className="font-poppins font-bold text-sm">Employabilité</p>
                                <div className="relative">
                                    <img
                                        src={tooltipSvg}
                                        alt="Info"
                                        className=" -mt-1 object-cover ml-2 cursor-pointer"
                                        onClick={toggleTooltip}
                                    />
                                    {tooltipVisible && (
                                        <div className="absolute w-40 bg-white p-2 text-black text-sm leading-tight rounded-md shadow-lg z-30">
                                            {profession.employabilityComment}
                                        </div>
                                    )}
                                </div>
                                </div>
                                <GradientBarWithArrow value={profession.employability} />
                            </div>
                            <hr className="my-3 p-2" />
                            <div className="flex justify-between p-2">
                                <p className="font-poppins font-bold text-sm">Formations</p>
                                <p className="font-poppins text-gray-400 font-bold text-sm">Bientôt disponible</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <LoginModal visible={isModalVisible} onClose={hideModal} />
        </LayoutNo>
    );
}
