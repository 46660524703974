
import React from 'react';
import { Carousel } from 'primereact/carousel';
import '../style/carousel.css';

const images = [
    { id: 14, src: 'https://linkystock.blob.core.windows.net/businesssectorsimages/Rectangle%2026102820-1-aerospatial.png', title: 'Ingénieur aéronautique  ' },
    { id: 37, src: 'https://linkystock.blob.core.windows.net/businesssectorsimages/Rectangle%2026102810-1-aeronautique.png', title: 'Pilote de ligne ' },
    { id: 347, src: 'https://linkystock.blob.core.windows.net/businesssectorsimages/Rectangle%2026102847-1-commerce.png', title: 'Chef(fe) de projet marketing' },
    { id: 154, src: 'https://linkystock.blob.core.windows.net/businesssectorsimages/Rectangle%2026102997-1-artisanat.png', title: 'Ébéniste' },

];

const CarouselSlider = () => {

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 4,
            numScroll: 4
        },
        {
            breakpoint: '768px',
            numVisible: 4,
            numScroll: 4
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];




    const goToDetailPage = (id) => {
        window.open(`/details/${id}`, '_blank', 'noopener,noreferrer');
    };

    const itemTemplate = (item) => {
        return (
            <div className="carousel-item cursor-pointer  " onClick={() => goToDetailPage(item.id)}>
                <img src={item.src} alt={item.title} className="carousel-image object-cover  " />
                <div className=" text-lg font-poppins font-bold text-white carousel-title backdrop-blur-sm  ">{item.title}</div>
            </div>
        );
    };

    return (
        <div className="carousel-container">
            <Carousel
                value={images}
                itemTemplate={itemTemplate}
                numVisible={4}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                circular
                autoplayInterval={false}
                className="p-carousel-custom"
            />
        </div>
    );
};

export default CarouselSlider;
