import React from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../../services/auth.provider";

// Higher-Order Component to protect routes
function PrivateRoute({ children }) {
 // const isAuthenticated = localStorage.getItem('accessToken'); // Check token in localStorage
  const { isAuthenticated } = useAuth();

  return isAuthenticated ? children : <Navigate to="/landing" replace />;
}

export default PrivateRoute;
