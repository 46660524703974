import Axios from './caller.service';

// ********************************************** Postbac **********************************************

const getSavedPostbac = (userId) => {    
    if (!userId) {
        return Promise.reject('User ID is required');
    }
    console.log("Fetching saved postbac for user_id:", userId);
    return Axios.get(`/get_saved_postbac/${userId}`);
}

const savePostbac = async (userId, postbacId) => {
    try {
        const requestBody = {
            "user_id": userId,
            "postbac_id": postbacId
        };
        console.log("Saving postbac with requestBody:", requestBody);
        
        const response = await Axios.post('/save_postbac/', requestBody);
        return response.data;

    } catch (error) {
        console.error('Error saving postbac data:', error);
        throw error; 
    }
};

const deletePostbac = async (userId, postbacId) => {
    try {
        const params = { postbac_id: postbacId };
        console.log("Deleting postbac with params:", params);
        
        const response = await Axios.delete(`/delete_postbac/${userId}`, { params });
        return response.data;

    } catch (error) {
        console.error('Error deleting postbac data:', error);
        throw error; 
    }
};

// ********************************************** MASTER **********************************************

const getSavedMaster = (userId) => {    
    if (!userId) {
        return Promise.reject('User ID is required');
    }
    console.log("Fetching saved master for user_id:", userId);
    return Axios.get(`/get_saved_master/${userId}`);
}

const saveMaster = async (userId, masterId) => {
    try {
        const requestBody = {
            "user_id": userId,
            "master_id": masterId
        };
        console.log("Saving master with requestBody:", requestBody);
        
        const response = await Axios.post('/save_master/', requestBody);
        return response.data;

    } catch (error) {
        console.error('Error saving master data:', error);
        throw error; 
    }
};

const deleteMaster = async (userId, masterId) => {
    try {
        const params = { master_id: masterId };
        console.log("Deleting master with params:", params);
        
        const response = await Axios.delete(`/delete_master/${userId}`, { params });
        return response.data;

    } catch (error) {
        console.error('Error deleting master data:', error);
        throw error; 
    }
};

// ********************************************** Professions **********************************************

const getSavedProfessions = (userId) => {
    if (!userId) {
        return Promise.reject('User ID is required');
    }
    console.log("Fetching saved professions for user_id:", userId);
    return Axios.get('/get_saved_professions/', {
        params: { user_id: userId }
    });
};


const saveProfession = async (userId, professionId) => {
    try {
        const requestBody = {
            "user_id": userId,
            "profession_id": professionId
        };
        console.log("Saving profession with requestBody:", requestBody);
        
        const response = await Axios.post('/save_profession/', requestBody);
        return response.data;

    } catch (error) {
        console.error('Error saving profession data:', error);
        throw error; 
    }
};

const deleteProfession = async (userId, professionId) => {
    try {
        const params = { profession_id: professionId };
        console.log("Deleting profession with params:", params);
        
        const response = await Axios.delete(`/delete_profession/${userId}`, { params });
        return response.data;

    } catch (error) {
        console.error('Error deleting profession data:', error);
        throw error; 
    }
};

export const ItemsService = {
    getSavedPostbac,
    savePostbac,
    deletePostbac,
    getSavedMaster,
    saveMaster,
    deleteMaster,
    getSavedProfessions,
    saveProfession,
    deleteProfession
};
