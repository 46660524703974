import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import Envelope from '../../../images/envelope.svg';
import LoginModal from './LoginModal';

// Modal.setAppElement('#root');

export function SuccessResetModal({ visible, onHideSuccessReset }) {
    const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
    console.log("SuccessResetModal", isLoginModalVisible)

    useEffect(() => {
        if (visible) {
            const timer = setTimeout(() => {
                setIsLoginModalVisible(true);
                onHideSuccessReset(); // Hide the success modal
            }, 2000); // Adjust the delay as needed

            return () => clearTimeout(timer);
        }
    }, []);

    // Use a separate useEffect to manage LoginModal visibility
    useEffect(() => {
        if (!visible) {
            setIsLoginModalVisible(false);
        }
    }, [visible]);

    return (
        <>
            <Modal
                isOpen={visible}
                onRequestClose={onHideSuccessReset}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '400px',
                        padding: '1rem',
                        borderRadius: '0.5rem',
                        backgroundColor: 'white',
                        textAlign: 'center',
                        boxSizing: 'border-box',
                    },
                }}
            >
                <button
                    type="button"
                    onClick={onHideSuccessReset}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'none',
                        border: 'none',
                        fontSize: '24px',
                        cursor: 'pointer',
                    }}
                >
                    &times;
                </button>
                <h2 className="text-xl font-semibold mb-2">Mot de passe modifié avec succès</h2>
                <div className="p-4">
                    <p className="text-sm mb-2">
                        Votre mot de passe a été réinitialisé avec succès. Vous allez être redirigé vers la page de connexion.
                    </p>
                    <div className="mt-2">
                        <img src={Envelope} alt="Icône d'email" className="inline-block h-20 w-20 max-w-full" />
                    </div>
                </div>
            </Modal>
            {isLoginModalVisible && (
                <LoginModal visible={isLoginModalVisible} onHide={() => setIsLoginModalVisible(false)} />
            )}        
            </>
    );
}
