import React from 'react';

export default function GradientBarWithArrow({ value }) {
    console.log("value", value)
    return (
        <div className="relative mt-2">
            {/* Linear Gradient Bar */}
            {/* <div className="flex justify-between mb-2">
                <span className="text-sm text-gray-900">Facile</span>
                <span className="text-sm text-gray-900">Difficile</span>
            </div> */}
            <div className="w-full h-2 rounded-full bg-gradient-to-r from-blue-200 to-blue-600"></div>

            {/* SVG Arrow */}
            <div
                className="absolute -top-[8px] transform -translate-x-1/2"
                style={{ left: `${value * 10}%` }}
            >
                <svg
                    width="30"
                    height="24"
                    viewBox="0 0 39 34"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g filter="url(#filter0_dd_465_13420)">
                        <path d="M19.5 6.00012L32.0574 27.7501H6.94263L19.5 6.00012Z" fill="white" />
                        <path
                            d="M20.366 5.50012L19.5 4.00012L18.634 5.50012L6.07661 27.2501L5.21058 28.7501H6.94263H32.0574H33.7894L32.9234 27.2501L20.366 5.50012Z"
                            stroke="#1681FF"
                            strokeWidth="2"
                        />
                    </g>
                    <defs>
                        <filter
                            id="filter0_dd_465_13420"
                            x="0.478516"
                            y="0.00012207"
                            width="38.043"
                            height="33.75"
                            filterUnits="userSpaceOnUse"
                            colorInterpolationFilters="sRGB"
                        >
                            <feFlood floodOpacity="0" result="BackgroundImageFix" />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="1.5" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_465_13420"
                            />
                            <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                            />
                            <feOffset dy="1" />
                            <feGaussianBlur stdDeviation="1" />
                            <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"
                            />
                            <feBlend
                                mode="normal"
                                in2="effect1_dropShadow_465_13420"
                                result="effect2_dropShadow_465_13420"
                            />
                            <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect2_dropShadow_465_13420"
                                result="shape"
                            />
                        </filter>
                    </defs>
                </svg>
            </div>
        </div>
    );
}
