const LOCAL_STORAGE_KEY = 'stepData';
const LOCAL_STORAGE_EXPIRY_KEY = 'storageExpiry';

const LOCAL_STORAGE_EXPIRY_TIME = 24 * 60 * 60 * 1000; // 24 hours

/**
 * Saves data to localStorage with an expiry time.
 * @param {Object} data - The data to be saved.
 */
const saveData = (data) => {
    try {
        const expiryTime = Date.now() + LOCAL_STORAGE_EXPIRY_TIME;
        const storageData = { data, expiryTime };
        localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(storageData));
    } catch (error) {
        console.error('Failed to save data to localStorage:', error);
    }
};

/**
 * Retrieves data from localStorage if it has not expired.
 * @returns {Object|null} - The data retrieved or null if not available or expired.
 */
const getData = () => {
    try {
        const storageData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
        if (storageData) {
            const { data, expiryTime } = storageData;
            if (Date.now() < expiryTime) {
                return data;
            } else {
                // Data has expired, remove it
                localStorage.removeItem(LOCAL_STORAGE_KEY);
            }
        }
        return null;
    } catch (error) {
        console.error('Failed to get data from localStorage:', error);
        return null;
    }
};

/**
 * Clears data from localStorage.
 */
const clearData = () => {
    try {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
    } catch (error) {
        console.error('Failed to clear data from localStorage:', error);
    }
};
const clearLocalStorage = () => {
    localStorage.clear();
    console.log('LocalStorage cleared');
};

export const LocalStorageService = {
    saveData,
    getData,
    clearData,
};
