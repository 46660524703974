import React, { useEffect, useState } from "react";
import RedHeartIcon from "../images/heart-filled.svg";
import WhiteHeartIcon from "../images/whiteheart.svg";
import {UserService} from "../services/userforms.service";

const TabContent = ({ userId, handleClick, isLiked }) => {
    const [data, setData] = useState([]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await UserService.getUserCalculate(userId);
                console.log("response guidance", response);

                const fetchedData = Array.isArray(response.data.data) ? response.data.data : [];
                setData(fetchedData);
            } catch (error) {
                console.error("Error fetching user data:", error);
                setData([]);
            }
        };

        fetchData();
    }, [userId]);

    const goToNewPage = (professionId) => () => {
        window.open(`/details/${professionId}`, '_blank', 'noopener,noreferrer');
    };


    return (
        <div>
            {/*{data.map((item, index) => (*/}
            {Array.isArray(data) && data.length > 0 ? (
                data.map((item, index) => (
                <div key={index} className="border-2 border-gray-300 bg-white p-4  rounded-lg mb-2">
                    <div className="block lg:hidden">
                        <div className="flex justify-between items-center mb-2">
                            <div className="flex items-center">
                                <img src={item.pictact} alt=" pic" className=" max-w-[2rem] mr-2"  />
                                <div className="  font-inter font-semibold text-[14px] text-[#141943]">{item.profession_name}</div>
                            </div>
                            <button onClick={handleClick} className="flex items-center justify-center">
                                <img
                                    src={isLiked ? RedHeartIcon : WhiteHeartIcon}
                                    alt="Love Icon"
                                />
                            </button>
                        </div>

                        <div onClick={goToNewPage(item.profession_id)} className="cursor-pointer">
                        {/* Divider */}
                        <hr className="border-t-2 border-gray-300 mb-2" />

                        {/* Labels and Values */}
                        <div className="flex flex-col space-y-2">

                            <div className="flex justify-between items-center">
                                <div className="min-w-[4rem] text-base text-[#141943] ">Secteur</div>
                                <div className=" font-inter font-medium text-[14px] text-[#141943]">{item.business_sect}</div>
                            </div>
                            <div className="flex justify-between items-center">
                                <div className=" min-w-[4rem] text-base text-[#141943] ">Score</div>
                                <div className=" font-inter font-medium text-[14px] text-[#141943]">{item.profession_score} %</div>
                            </div>
                        </div>

                        {/* Competences Section */}
                        <div className="flex flex-col mt-2">
                            <div className=" min-w-[4rem] text-base text-[#141943] ">Compétences:</div>
                            <div className="flex flex-wrap gap-2 mt-2">
                                {/* Split skills string into an array and map over it */}
                                {item.skills?.split(',').map((comp, compIndex) => (
                                    <span key={compIndex} className="py-1 px-2 text-xs font-semibold bg-[#EAEFF4] text-[#141943] rounded-full">
                                        {comp.trim()}
                                    </span>
                                ))}
                            </div>

                        </div>
                        </div>
                    </div>

                    {/* Content for larger screens */}
                    <div className="hidden lg:block">
                        <div className="grid grid-cols-[auto_1fr_auto] items-start gap-2">
                            <div className="flex justify-center items-center p-2">
                                <img
                                    src={item.pictact}
                                    alt="Hand Heart Icon"
                                    className="max-w-[3rem] mr-2"
                                />
                            </div>
                            <div className="flex flex-col cursor-pointer" onClick={goToNewPage(item.profession_id)}>
                                {/*<div className="text-lg font-semibold mb-2">{item.profession}</div>*/}
                                <div className="flex mt-1">
                                    <div className="min-w-[8rem] text-[14px] text-[#434769] font-inter">Métier :</div>
                                    <div className="text-sm text-[#141943] font-inter font-bold">{item.profession_name}</div>
                                </div>
                                <div className="flex mt-1">
                                    <div className="min-w-[8rem] text-[14px] text-[#434769] font-inter">Secteur :</div>
                                    <div className="text-sm font-inter text-[#141943]">{item.business_sect}</div>
                                </div>
                                <div className="flex mt-1">
                                    <div className="min-w-[8rem] text-[14px] text-[#434769] font-inter">Score :</div>
                                    <div className="text-sm  font-inter text-[#141943]">{item.profession_score} %</div>
                                </div>
                                <div className="flex mt-1">
                                    <div className="min-w-[8rem] text-[14px] text-[#434769] font-inter pt-[1px]">Compétences :</div>
                                    <div className="flex flex-wrap gap-2 text-base tracking-[0.56px] text-dark-8">
                                        {item.skills?.split(',').map((comp, compIndex) => (
                                            <span key={compIndex} className="py-1 px-2 text-xs text-[#141943] font-semibold bg-[#EAEFF4] rounded-full">
                                        {comp.trim()}
                                    </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="flex justify-center items-center">
                                <button onClick={handleClick} className="flex items-center justify-center p-2">
                                    <img
                                        src={isLiked ? RedHeartIcon : WhiteHeartIcon}
                                        alt="Love Icon"
                                        className="w-[24px] h-[24px]"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            ))) : (
                <p>No data available</p>
            )}
        </div>
    );
};

export default TabContent;
