import React, { useState, useRef, useEffect } from 'react';
import SituationStep from "./steps/SituationStep";
import StartFormationStep from "./steps/StartFormationStep";
import CloseToFinishStep from "./steps/CloseToFinishStep";
import SignUpStep from './steps/SignUpStep';
import Filiere from './steps/Filiere';
import SpecialiteStep from "./steps/SpecialiteStep";
import { Toast } from 'primereact/toast';
import { LocalStorageService } from './../services/localStorage.service';
import NiveauActuel from './steps/NiveauActuel';
import BacSpeProStep from './steps/BacSpeProStep';
import BacSpeTechnoStep from './steps/BacSpeTechnoStep';
import BacSpeGeneralStep from './steps/BacSpeGeneralStep';

export default function StepperPage() {
    const [stepData, setStepData] = useState(() => LocalStorageService.getData() || {});
    const [currentStep, setCurrentStep] = useState(1);
    const toastRef = useRef(null);
    const [prevSpecialiteStep, setPrevSpecialiteStep] = useState(null);
    const [errors, setErrors] = useState({}); // État pour les erreurs de validation

    useEffect(() => {
        // Save data in local storage whenever stepData changes
        LocalStorageService.saveData(stepData);
    }, [stepData]);

    useEffect(() => {
        const handleResize = () => {
            if (window.matchMedia("(min-width: 1024px)").matches) { // Écran de bureau
                document.body.classList.add("overflow-y-hidden");
            } else {
                document.body.classList.remove("overflow-y-hidden");
            }
        };

        // Vérifier la taille lors du montage
        handleResize();

        // Ajouter un écouteur d'événement pour la redimension de la fenêtre
        window.addEventListener("resize", handleResize);

        // Nettoyage
        return () => {
            window.removeEventListener("resize", handleResize);
            document.body.classList.remove("overflow-y-hidden");
        };
    }, []);
    
    const handleFinish = async () => {
        try {
            LocalStorageService.clearData();
            alert('Finished and user data saved!');
        } catch (error) {
            console.error("Error during sign-up or data save:", error);
            showMessage('An error occurred during sign-up or data save.', 'error');
        }
    };

    const renderBacStepFour = () => {
        if (stepData[1] === 1) {
            switch (stepData[3]) {
                case 1:
                    return <BacSpeGeneralStep previousSelection={stepData[2]} selectedData={stepData[4]} setSelectedData={data => handleDataUpdate(4, data, false)} />;
                case 2:
                    return <BacSpeTechnoStep selectedData={stepData[4]} setSelectedData={data => handleDataUpdate(4, data, true)} />;
                case 3:
                    return <BacSpeProStep selectedData={stepData[4]} setSelectedData={data => handleDataUpdate(4, data, true)} />;
                default:
                    return null;
            }
        }
        return null;
    };

    const renderStepThree = () => {
        if (stepData[1] === 1) {
            //3a
            return <Filiere selectedData={stepData[3]} setSelectedData={data => handleDataUpdate(3, data)} />;
        } else {
            return <SpecialiteStep selectedData={stepData[3]} setSelectedData={data => handleDataUpdate(3, data, false)} />;
        }
    };

    const stepComponents = {
        1: <SituationStep selectedData={stepData[1]} setSelectedData={data => handleDataUpdate(1, data)} />,
        // 2a, 2b, 2c
        2: <NiveauActuel previousSelection={stepData[1]} selectedData={stepData[2]} setSelectedData={data => handleDataUpdate(2, data)} />,
        3: renderStepThree(),
        4: renderBacStepFour(),
        5: <StartFormationStep selectedData={stepData[5]} setSelectedData={data => handleDataUpdate(5, data)} />,
        6: <CloseToFinishStep data={stepData[6]} setUserData={(data) => handleDataUpdate(6, data, false)} errors={errors} />, // Ajout des erreurs ici
        7: <SignUpStep onSubmit={handleFinish} />
    };

    const handleNext = () => {
        if (validateStepData(currentStep)) {
            if (currentStep === 3 && stepData[1] !== 1) {
                // If current step is 3 and it's SpecialiteStep, go to step 6
                setPrevSpecialiteStep(3); // Store the current step (3) as the last visited special step
                setCurrentStep(6);
            } else {
                
                setCurrentStep(prev => prev + 1);
            }
        } else {
            console.log('Data not available for the next step');
        }
    };

    const handleBack = () => {
        let prevStep = currentStep - 1;
        if (currentStep === 6 && prevSpecialiteStep !== null) {
            prevStep = prevSpecialiteStep; // Go back to the last special step (3)
            setPrevSpecialiteStep(null); // Clear the stored special step
        } else if (currentStep === 6 && (stepData[1] === 5 || stepData[2] === 1 || (stepData[1] !== 1 && stepData[3] !== undefined))) {
            prevStep = 1; // Skip back to step 1 if certain conditions are met
        }

        setCurrentStep(prevStep);
    };

    const showMessage = (message, severity) => {
        toastRef.current.show({ severity, summary: message, life: 1000 });
    };

    const handleDataUpdate = (step, data, autoAdvance = true) => {
        setStepData(prevData => {
            const newData = { ...prevData, [step]: data };

            // Determine the next step logic
            let nextStep = step;
            if (step === 1 && data === 5) {
                nextStep = 6;
            } else if (step === 2 && data === 1) {
                nextStep = 6;
            } else if (step === 3 && [2, 3, 4].includes(prevData[1])) {
            
                nextStep = 6;
            } else if (autoAdvance) {
                nextStep = step + 1;
            }

            // if (autoAdvance) {
            //     setCurrentStep(nextStep);
            // }
            if (autoAdvance) {
                // Add delay of 500ms before moving to the next step
                setTimeout(() => {
                    setCurrentStep(nextStep);
                }, 500);
            }
            return newData;
        });
    };

    const validateStepData = (step) => {
        console.log(step, "validateStepData");
    
        if (step >= 6) {
            let stepData = JSON.parse(localStorage.getItem('stepData')) || {};
            console.log(stepData, "stepData");
            const stepsData = stepData.data || {};
                const checkData = stepsData[6];
            console.log(checkData, "checkData");
    
            let newErrors = {};
    
            if (!checkData.user_name || !checkData.user_name.trim()) newErrors.user_name = 'Le nom est requis';
            if (!checkData.user_surname || !checkData.user_surname.trim()) newErrors.user_surname = 'Le prénom est requis';
            if (!checkData.postalCode || !checkData.postalCode.trim()) newErrors.postalCode = 'Le code postal est requis';
            if (!checkData.country) newErrors.country = 'Le pays est requis';
            if (!checkData.user_year_of_birth || !checkData.user_year_of_birth.trim()) newErrors.user_year_of_birth = 'L\'année de naissance est requise';
            if (!checkData.phone_number || !checkData.phone_number.trim()) newErrors.phone_number = 'Le numéro de téléphone est requis';
    
            setErrors(newErrors);
            console.log(newErrors, "errors");
                return Object.keys(newErrors).length === 0;
        } else {
            const data = stepData[step];
            console.log(data, "data");
    
            return data != null;
        }
    };
    

    return (
        <div className="relative w-full md:h-screen flex flex-col">
            <Toast ref={toastRef} position="center" />

            <div className="absolute top-10 left-0 w-full px-2">
                <div className="relative h-2 rounded-full bg-gray-100">
                    <div className="relative h-2 rounded-full bg-blue-400" style={{ width: `${(currentStep / 7) * 100}%` }}></div>
                </div>
            </div>

            <div className="flex-grow mt-10 mb-10 p-1">
                {stepComponents[currentStep]}
            </div>

            <div className="md:absolute md:bottom-28 w-full flex justify-between px-2 mb-2">
                <div>
                    {currentStep > 1 && (
                        <button
                            type="button"
                            className="py-2 px-3 inline-flex text-start gap-x-1 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 hover:bg-gray-50 focus:outline-none"
                            onClick={handleBack}
                        >
                            <svg className="shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                <path d="m15 18-6-6 6-6"></path>
                            </svg>
                            Retour
                        </button>
                    )}
                </div>
                <div>
                    {currentStep <= 6 && (
                        <button
                            type="button"
                            className="py-2 px-3 w-32 inline-flex justify-center items-center text-center gap-x-1 text-sm font-medium rounded-lg border border-transparent bg-blue-500 text-white hover:bg-blue-700 focus:outline-none"
                            onClick={handleNext}
                        >
                            Suivant
                        </button>
                    )}
                </div>
            </div>
        </div>
    );
}
