import React, {useState, useRef, useEffect} from 'react';
import ArrowDown from '../../images/arrowDown';

const CustomSelectSearch = ({options, onChange, optionKey, optionLabel, placeholder,clearFilters }) => {
    const [selectedOption, setSelectedOption] = useState(null);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownButtonRef = useRef(null);
    const dropdownMenuRef = useRef(null);

    const toggleDropdown = () => {
        setIsDropdownOpen((prevState) => !prevState);
    };

    const handleOptionClick = (option) => {
        if (selectedOption && selectedOption[optionKey] === option[optionKey]) {
            setSelectedOption(null);
            if (onChange) onChange(null);
        } else {
            setSelectedOption({[optionKey]: option[optionKey], [optionLabel]: option[optionLabel]});
            if (onChange) onChange(option);  // Call onChange with the selected option
        }
        setIsDropdownOpen(false);  // Close the dropdown after selection
    };

    const handleClickOutside = (event) => {
        if (
            dropdownButtonRef.current &&
            !dropdownButtonRef.current.contains(event.target) &&
            dropdownMenuRef.current &&
            !dropdownMenuRef.current.contains(event.target)
        ) {
            setIsDropdownOpen(false);
        }
    };
    useEffect(() => {
        if (clearFilters) {
            setSelectedOption(null);
        }
    }, [clearFilters]);


    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative">
            <button
                className="flex justify-between items-center w-full px-4 py-2.5 border border-[#D0DBE7] bg-white rounded-lg  focus:outline-none"
                onClick={toggleDropdown}
                ref={dropdownButtonRef}
            >
                <span className="flex items-center">
                    {placeholder}{selectedOption && (
                        <span className="ml-2 flex items-center">
                            <span
                                className="ml-2 text-xs font-bold text-white bg-black rounded-full p-1 w-12 h-4 flex items-center justify-center">
                                1
                            </span>
                        </span>
                )}
                </span>
                <span className="ml-2"><ArrowDown/></span>
            </button>
            {isDropdownOpen && (
                <div
                    ref={dropdownMenuRef}
                    className="absolute left-0 mt-2 w-full bg-white border border-[#D0DBE7] rounded-lg shadow-lg z-20"
                >
                    <ul className="max-h-60 overflow-y-auto py-2">
                        {options.map((option) => (
                            <li
                                key={option[optionKey]}
                                className={`flex items-center p-2 hover:bg-gray-100 ${
                                    selectedOption && selectedOption[optionKey] === option[optionKey] ? 'bg-blue-50' : ''
                                }`}
                            >
                                <label className="flex items-center w-full cursor-pointer">
                                    <input
                                        type="checkbox"
                                        checked={selectedOption && selectedOption[optionKey] === option[optionKey]}
                                        onChange={() => handleOptionClick(option)}
                                        className="form-checkbox rounded mr-2"
                                        aria-labelledby={`option-label-${option[optionKey]}`}
                                    />
                                    <span
                                        id={`option-label-${option[optionKey]}`}
                                        className="text-sm text-gray-800"
                                    >
            {option[optionLabel]}
        </span>
                                </label>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CustomSelectSearch;
