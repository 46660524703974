import Axios from './caller.service';

/**
 * Récupération de la liste des utilisateurs
 * @returns {Promise}
 */
let getUserStatus = () => {
    return Axios.get('/User_Status/');
}

let getStudiesLevel = (status = 0, graduate = 0) => {
    let params = {};
    
    if (status !== 0) {
        params.status = status;
    }
    
    if (graduate !== 0) {
        params.graduate = graduate;
    }
    console.log("params ", params)
    return Axios.get('/studies_level/', { params: params });
}

let getBacSpeciality = () => {
    return Axios.get('/bac_speciality/');
}

let getSubjectsCategries = () => {
    return Axios.get('/subjects_categries/');
}

let getBacSpePro = () => {
    return Axios.get('/bac_spe_pro/');

}

let getSalaireRange = () => {
    return Axios.get('/32_salary_ranges/');

}
let getBusinessSector = () => {
    return Axios.get('/31_business_sector/');

}

let getBacSpeTechno = () => {
    return Axios.get('/bac_spe_techno/');
}

let getBacSpeGeneral = () => {
    return Axios.get('/bac_spe_general/');
}

let getBacType = () => {
    return Axios.get('/bac_type/');
}

let get17AreaStudies = () => {
    return Axios.get('/17_area_studies/');
}

let getUserCalculate = (userId) => {
    return Axios.get(`/user/user_calculate/${userId}`);
}

let getUserSubmissionData = (userId) => {
    return Axios.get(`/GetUserSubmissionData/${userId}`);
}

export const UserService = {
    getUserStatus,
    getStudiesLevel,
    getBacSpeciality,
    getSubjectsCategries,
    getBacSpePro,
    getUserSubmissionData,
    getBacSpeTechno,
    getBacSpeGeneral,
    getBacType,
    getUserCalculate,
    getBusinessSector,

    get17AreaStudies,
    getSalaireRange
}
