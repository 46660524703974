import React, { useState } from 'react';
import GoogleIcon from "../images/google.svg";
import AppleIcon from "../images/apple.svg";
import EyeHide from "../images/eye-hide.svg";
import EyeShow from "../images/eye-view.svg";
import { AuthService } from '../services/auth.service';
import warningIcon from '../images/modal/warning.svg';
import { Link } from 'react-router-dom';

export default function SignUp() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [error, setError] = useState('');
    const [isLoading, setIsLoading] = useState(false); // État de chargement

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const toggleConfirmPasswordVisibility = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleSubmit = async () => {
        setIsLoading(true); // Démarrer le chargement

        if (!email || !password || !confirmPassword) {
            setError('Veuillez remplir tous les champs.');
            setIsLoading(false);
            return;
        }

        if (password !== confirmPassword) {
            setError('Les mots de passe ne correspondent pas.');
            setIsLoading(false);
            return;
        }

        try {
            const res = await AuthService.SignUp(email, password);
            if(res.data.status === 'success'){
                console.log("res ", res);
                setError('');
            }else{
                setError('Vérifiez les données et réessayez.');
            }
        } catch (error) {
            setError('Vérifiez les données et réessayez.');
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen flex items-center justify-center bg-gray-100 sm:px-6 lg:px-8">
            <div className="max-w-md w-full bg-white p-5 shadow-lg rounded-lg">
                <h1 className="text-2xl font-bold text-center text-gray-900 mb-4">Inscription</h1>
                {error && (
                    <div className="bg-red-50 text-red-500 px-4 py-2 rounded-md flex items-center mb-4">
                        <img src={warningIcon} alt="Warning" className="h-4 mr-2" />
                        <span>{error}</span>
                    </div>
                )}
                <div className="space-y-4">
                    <div className="flex flex-col">
                        <label className="block text-sm font-medium text-gray-700 mb-1">E-mail</label>
                        <input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="Texte"
                            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 bg-gray-100"
                        />
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Mot de passe</label>
                        <div className="relative w-full">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="********"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 bg-gray-100 pr-10"
                            />
                            <button
                                type="button"
                                /*className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"*/
                                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600 sm:right-0"
                                onClick={togglePasswordVisibility}
                            >
                                {showPassword ? (
                                    <img src={EyeShow} alt="Afficher le mot de passe" className="h-6 w-6" />
                                ) : (
                                    <img src={EyeHide} alt="Masquer le mot de passe" className="h-6 w-6" />
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-medium text-gray-700 mb-1">Confirmer le mot de passe</label>
                        <div className="relative w-full">
                            <input
                                type={showConfirmPassword ? 'text' : 'password'}
                                value={confirmPassword}
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                placeholder="********"
                                className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 bg-gray-100 pr-10"
                            />
                            <button
                                type="button"
                                /*className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600"*/
                                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-600 sm:right-0"
                                onClick={toggleConfirmPasswordVisibility}
                            >
                                {showConfirmPassword ? (
                                    <img src={EyeShow} alt="Afficher le mot de passe" className="h-6 w-6" />
                                ) : (
                                    <img src={EyeHide} alt="Masquer le mot de passe" className="h-6 w-6" />
                                )}
                            </button>
                        </div>
                    </div>
                </div>

                <p className="text-xs text-gray-500 mt-3 text-center">
                    En cliquant sur s’inscrire, tu acceptes les <a href="#" className="text-blue-600 underline">CGU</a> de Linkyways.
                </p>
                <button
                    onClick={handleSubmit}
                    disabled={isLoading}
                    className="w-full mt-4 py-2 px-4 bg-blue-600 text-white text-sm font-medium rounded-md shadow-sm hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                >   
                {isLoading ? 'Chargement...' : 'S’inscrire'}
                </button>
                <div className="flex items-center my-4">
                    <div className="flex-grow border-t border-gray-300"></div>
                    <span className="mx-4 text-gray-400 font-bold text-sm">ou</span>
                    <div className="flex-grow border-t border-gray-300"></div>
                </div>
                <div className="space-y-2">
                    <button
                        type="button"
                        className="w-full flex items-center justify-center gap-2 py-2 px-4 text-sm font-medium rounded-full border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                        <img src={GoogleIcon} alt="Google" />
                        <span>Continuer avec Gmail</span>
                    </button>
                    <button
                        type="button"
                        className="w-full flex items-center justify-center gap-2 py-2 px-4 text-sm font-medium rounded-full border border-gray-300 bg-white text-gray-900 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
                    >
                        <img src={AppleIcon} alt="Apple" />
                        <span>Continuer avec Apple</span>
                    </button>
                </div>
                <p className="text-xs text-black-200 mt-4 text-center">
                    Déjà un compte ? <Link to="/login" className="text-gray-650 underline">Se connecter</Link>
                </p>
            </div>
        </div>
    );
}
