// import React from 'react';
// import ShareIcon from '../images/share.svg';
// import HeartIcon from '../images/heart.svg';

// const MetierCard = ({ imgSrc, smtitle, title, chips, frompriceDebutant, topriceDebutant, frompriceExp, topriceExp }) => {
//     return (
//         <div className="bg-white rounded-lg shadow-lg p-2 relative">
//             <div className="flex justify-between items-start">
//                 <div className="flex items-center space-x-2">
//                     <img src={imgSrc} alt="logo" className="object-cover" />
//                     {/* Small Title */}
//                     <div>
//                         <h4 className="font-medium text-sm text-gray-600">{smtitle}</h4>
//                     </div>
//                 </div>
//                 <div className="flex space-x-1 justify-end">
//                     <button>
//                         <img src={ShareIcon} alt="share" className="w-8 h-8" />
//                     </button>
//                     <button>
//                         <img src={HeartIcon} alt="favorite" className="w-8 h-8" />
//                     </button>
//                 </div>
//             </div>
//             <h3 className="font-bold mt-4">{title}</h3>
//             <hr className="my-2" />
//             <div className="flex flex-wrap gap-2">
//                 {chips.map((chip, idx) => (
//                     <span key={idx} className="bg-gray-200 text-gray-700 text-xs font-semibold px-2 py-1 rounded">
//                         {chip}
//                     </span>
//                 ))}
//             </div>
//             <div className="flex justify-between items-center mt-2">
//                 <p className="text-xs font-medium">Débutant :</p>
//                 <p className="text-xs font-medium text-gray-600">€{frompriceDebutant} - €{topriceDebutant}</p>
//             </div>
//             <div className="flex justify-between items-center mt-2">
//                 <p className="text-xs font-medium">Expérimenté :</p>
//                 <p className="text-xs font-medium text-gray-600">€{frompriceExp} - €{topriceExp}</p>
//             </div>
//         </div>
//     );
// };

// export default MetierCard;

import React, { useState } from 'react';
import HeartIcon from '../images/heart.svg';
import FilledHeartIcon from '../images/heart-filled.svg';

const MetierCard = ({
    imgSrc,
    smtitle,
    title,
    chips,
    frompriceDebutant,
    topriceDebutant,
    frompriceExp,
    topriceExp,
    professionId,
    saveProfession,
    deleteProfession,
    saved,

}) => {
    const [isSaved, setIsSaved] = useState(saved !==0);

    const handleHeartClick = async () => {
        if (isSaved) {
            // Remove from saved professions
            try {
                await deleteProfession(professionId);
                setIsSaved(false);
            } catch (error) {
                console.error('Error deleting profession:', error);
            }
        } else {
            // Save profession
            try {
                await saveProfession(professionId);
                setIsSaved(true);
            } catch (error) {
                console.error('Error saving profession:', error);
            }
        }
    };

    const goToNewPage = () => {
        window.open(`/details/${professionId}`, '_blank', 'noopener,noreferrer');
    };

    return (
        <div className="bg-white rounded-lg shadow-lg p-2 relative cursor-pointer" >
            <div className="flex justify-between items-start">
                <div className="flex items-center space-x-2">
                    <img src={imgSrc} alt="logo" className="max-w-12 p-1" />
                    {/* Small Title */}
                    <div>
                        <h4 className="font-medium text-sm text-gray-600">{smtitle}</h4>
                    </div>
                </div>
                <div className="flex space-x-1 justify-end">

                    {/* <button>
                        <img src={ShareIcon} alt="share" className="w-8 h-8" />
                    </button> */}
                    <button onClick={handleHeartClick}>
                        <img
                            src={isSaved ? FilledHeartIcon : HeartIcon}
                            alt={isSaved ? "remove from favorites" : "add to favorites"}
                            className="object-cover min-w-6"
                        />
                    </button>
                </div>
            </div>
            <div onClick={goToNewPage}>
                <h3 className="font-bold mt-4">{title}</h3>
                <hr className="my-2" />
                <div className="flex flex-wrap gap-2">
                    {chips.map((chip, idx) => (
                        <span key={idx} className="bg-gray-200 text-gray-700 text-xs font-semibold px-2 py-1 rounded">
                        {chip}
                    </span>
                    ))}
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="text-xs font-medium">Débutant :</p>
                    <p className="text-xs font-medium text-gray-600">{frompriceDebutant}€ - {topriceDebutant}€</p>
                </div>
                <div className="flex justify-between items-center mt-2">
                    <p className="text-xs font-medium">Expérimenté :</p>
                    <p className="text-xs font-medium text-gray-600">{frompriceExp}€ - {topriceExp}€</p>
                </div>
            </div>

        </div>
    );
};

export default MetierCard;
