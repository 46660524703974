import React, { useEffect, useState } from 'react';
import CustomRadio from '../../utils/CustomRadio';
import { UserService } from '../../services/userforms.service';
import CustomMultiRadio from './cards/CustomMultiRadio';
import CircularProgress from "@mui/material/CircularProgress";

export default function BacSpeGeneralStep({ previousSelection, selectedData = [], setSelectedData }) {
    const [currentStep, setCurrentStep] = useState(0);
    const [responseData, setResponseData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    console.log("BacSpeGeneralStep ", previousSelection);
    
    const getItemsPerPage = () => {
        if (window.innerWidth < 640) return 4; // mobile
        if (window.innerWidth < 768) return 4; // tablet
        return 6; // desktop
    };

    const [itemsPerPage, setItemsPerPage] = useState(getItemsPerPage());

    useEffect(() => {
        fetchData();
        const handleResize = () => {
            setItemsPerPage(getItemsPerPage());
        };
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []); // Empty dependency array to ensure it runs only once

    useEffect(() => {
        console.log("selectedData changed: ", selectedData);
        // No fetchData call here to avoid unintended re-fetches
    }, [selectedData]); // Only log when selectedData changes

    const handleResize = () => {
        setItemsPerPage(getItemsPerPage());
    };

    const fetchData = async () => {
        try {
            const response = await UserService.getBacSpeGeneral();
            if (response.data.status === 'success') {
                const fetchedTypes = response.data.data.map((item) => ({
                    id: item.bac_spe_general_id,
                    value: item.spe_name
                }));
                setResponseData(fetchedTypes);
            } else {
                setError(response.data.message || 'An error occurred');
            }
        } catch (err) {
            setError(err.message || 'An error occurred');
        } finally {
            setLoading(false);
        }
    };
    if (loading) {
        return(
            <div className="flex justify-center mt-5">
                <CircularProgress />
            </div>
        )
    }

    const handleSelection = (event) => {
        const value = parseInt(event.target.id, 10);
        console.log("bac general value: ", value);

        // Ensure selectedData is an array
        const newSelectedData = Array.isArray(selectedData) ? [...selectedData] : [];

        // Check if the item is already selected
        const isItemSelected = newSelectedData.includes(value);

        // Conditions based on previousSelection value
        const limit = previousSelection === 2 ? 3 : previousSelection === 3 ? 2 : null;

        if (isItemSelected) {
            // If the item is already selected, remove it from the array
            const updatedSelectedData = newSelectedData.filter((selectedItemId) => selectedItemId !== value);
            setSelectedData(updatedSelectedData);
            console.log("updated selectedData: ", updatedSelectedData);
        } else {
            if (limit && newSelectedData.length < limit) {
                // If under the limit, add the item
                newSelectedData.push(value);
                setSelectedData(newSelectedData);
                console.log("updated selectedData: ", newSelectedData);
            } else if (!limit) {
                // If no limit, allow adding the item
                newSelectedData.push(value);
                setSelectedData(newSelectedData);
                console.log("updated selectedData: ", newSelectedData);
            } else {
                // Notify the user they can't select more items
                alert(`Vous ne pouvez sélectionner que ${limit} spécialités.`);
            }
        }
    };

    const handleNext = () => {
        if (currentStep < Math.ceil(responseData.length / itemsPerPage) - 1) {
            setCurrentStep(currentStep + 1);
        }
    };

    const handlePrev = () => {
        if (currentStep > 0) {
            setCurrentStep(currentStep - 1);
        }
    };

    const startIndex = currentStep * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const currentRadios = responseData.slice(startIndex, endIndex);

    return (
        <div className="mt-5 px-4 md:px-0">
            <p className="font-poppins text-2xl mb-1">Quelles sont vos spécialités ?</p>
            <p className="font-inter text-[16px] font-normal leading-[25.6px] text-left mt-2 mb-2">Sélectionnez les spécialités que vous avez choisies.</p>
            <div className="relative flex items-center justify-center py-5">
                {currentStep > 0 && (
                    <button
                        onClick={handlePrev}
                        className="absolute left-[-30px] md:left-[-40px] px-2 py-1 border-2 border-black bg-gray-100 rounded-full text-lg md:text-xl"
                    >
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6 1L1 6L6 11" stroke="#141943" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                )}
                <div className="flex ">
                    <div className={`grid grid-cols-1 md:grid-cols-2 gap-1 px-2`}>
                        {currentRadios.map((option) => (
                            <CustomMultiRadio
                                id={option.id}
                                key={option.id}
                                value={option.value}
                                label={option.value}
                                checked={Array.isArray(selectedData) ? selectedData.includes(option.id) : selectedData == option.id}
                                onChange={handleSelection}
                            />
                        ))}
                    </div>
                </div>
                {currentStep < Math.ceil(responseData.length / itemsPerPage) - 1 && (
                    <button
                        onClick={handleNext}
                        className="absolute right-[-30px] md:right-[-40px] px-2 py-1 border-2 border-black bg-gray-100 text-white rounded-full md:text-xl"
                    >
                        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1L6 6L1 11" stroke="#141943" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                )}
            </div>
        </div>
    );
}
