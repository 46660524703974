import CryptoJS from 'crypto-js';

// Define your secret key
const secretKey = 'jAQ50rQ5JRcaJXf8ENGGUkU9Zw5jzPH9m1JprBrB/Ng=';

// Encrypt function
let encryptData = (data) => {
    return CryptoJS.AES.encrypt(data, secretKey).toString();
};

// Decrypt function
let decryptData = (encryptedData) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
};

export const CryptoService = {
    encryptData,
    decryptData
}