import React, { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import logo from "../images/logo.svg";
import DefaultUser from "../images/user.svg";
import MentionsLegalesDialog from "../utils/MentionsLegale";
import ConditionsGeneralesDialog from "../utils/ConditionsGenerales";
import { Link } from 'react-router-dom';
import {useAuth} from "../services/auth.provider";

export default function Navbar({ onToggleSidebar }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [openMentionsDialog, setOpenMentionsDialog] = useState(false);
    const [openConditionsDialog, setOpenConditionsDialog] = useState(false);
    const { isAuthenticated, logout } = useAuth();

    const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);
    const toggleDropdown2 = () => setDropdownVisible(!dropdownVisible);

    return (
        <nav className={`bg-white p-1 ${!isAuthenticated ? 'py-2' : ''} flex justify-between items-center fixed top-0 left-0 w-full z-50 `}>
            <div className="flex items-center">
                {/* Button to open sidebar */}
                <button

                    onClick={onToggleSidebar}
                    className="lg:hidden  -mr-2 text-gray-700 hover:text-black"
                >
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <circle cx="12" cy="6" r="2" fill="currentColor"/>
                        <circle cx="12" cy="12" r="2" fill="currentColor"/>
                        <circle cx="12" cy="18" r="2" fill="currentColor"/>
                    </svg>
                </button>

                {/* Logo */}
                <Link to="/landing">
                    <img
                        alt="logo"
                        src={logo}
                        width="170"
                        className="pl-3 cursor-pointer"
                    />
                </Link>
            </div>

            {isAuthenticated && (
            <div className="flex items-center space-x-2 relative">
                <div className="hidden lg:flex items-center p-2 space-x-2">
                    <div className="relative inline-block text-left">
                    <div className="flex items-center cursor-pointer mr-4" onClick={toggleDropdown2}>
                        <Avatar
                            alt="user"
                            className="rounded-full bg-gray-100 object-fit border-1 border-[#2F8FFF]"
                            src={DefaultUser}
                            sx={{ width: 30, height: 30 }}
                        />
                        <div className="ml-2 text-black">
                            <p className="text-xs font-semibold m-0 leading-tight">
                                {localStorage.getItem('user_surname')} {localStorage.getItem('user_name')?.charAt(0).toUpperCase()}
                            </p>
                        </div>
                    </div>


                        {dropdownVisible && (
                            <div className="absolute right-0 mt-2 w-52 bg-white border border-gray-200 rounded-lg shadow-lg z-10">
                                {/*<div className="py-1  bg-gray-100 rounded-t-lg dark:bg-neutral-700">*/}
                                    <div className=" p-1 bg-gray-100 rounded-t-lg ">

                                    <p  className="   px-2 rounded-lg text-sm  font-semibold text-gray-700  focus:outline-none focus:bg-gray-100 ">
                                        {localStorage.getItem('user_email')}
                                    </p>
                                </div>
                                <div className="m-2 p-1 first:pt-0 last:pb-0">
                                    <a
                                        className="flex cursor-pointer items-center gap-x-2 px-2 rounded-lg text-sm text-gray-800  focus:outline-none focus:bg-gray-100 "
                                        onClick={() => setOpenMentionsDialog(true)}
                                    >
                                        Mentions légales
                                    </a>
                                    <hr className="m-2"/>
                                    <a
                                        className="flex cursor-pointer items-center gap-x-2 px-2 rounded-lg text-sm text-gray-800  focus:outline-none focus:bg-gray-100 "
                                        onClick={() => setOpenConditionsDialog(true)}
                                    >
                                        Conditions générales
                                    </a>
                                    <hr className="m-2"/>
                                    <a className="flex cursor-pointer items-center gap-x-2 px-2 rounded-lg text-sm text-gray-800  focus:outline-none focus:bg-gray-100 "
                                       onClick={(e) => {
                                           e.preventDefault();
                                           logout();
                                       }}
                                    >
                                        logout
                                    </a>
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div className="lg:hidden relative mt-1">
                    <Avatar
                        alt="user"
                        className="rounded-full bg-gray-100 object-fit border-1 border-[#2F8FFF]"
                        src={DefaultUser}
                        sx={{ width: 30, height: 30 }}
                        onClick={toggleDropdown}
                    />
                    {isDropdownOpen && (
                        <div className="absolute top-8 right-0 bg-white text-black border border-gray-300 rounded-md shadow-lg p-2 w-52">
                            <div className="p-2 rounded-t-lg ">
                                <p className="text-sm font-medium text-gray-800 dark:text-neutral-300"> {localStorage.getItem('user_email')}</p>
                            </div>
                            <div className="m-1 p-1 first:pt-0 last:pb-0">
                                <a
                                    className="flex items-center gap-x-2 px-2 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 "
                                    href="#"
                                    onClick={() => setOpenMentionsDialog(true)}
                                >
                                    Mentions légales
                                </a>
                                <hr className="m-2"/>
                                <a
                                    className="flex items-center gap-x-2 px-2 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 "
                                    href="#"
                                    onClick={() => setOpenConditionsDialog(true)}
                                >
                                    Conditions générales
                                </a>
                                <hr className="m-2"/>
                                <a className="flex items-center gap-x-2 px-2 rounded-lg text-sm text-gray-800 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 "
                                   onClick={(e) => {
                                       e.preventDefault();
                                       logout();
                                   }}
                                >
                                    logout
                                </a>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            )}

            {/* Dialog Components */}
            <MentionsLegalesDialog open={openMentionsDialog} onClose={() => setOpenMentionsDialog(false)} />
            <ConditionsGeneralesDialog open={openConditionsDialog} onClose={() => setOpenConditionsDialog(false)} />
        </nav>
    );
}
