import Axios from './caller.service';

let get35Professions = () => {
    return Axios.get('/35_professions/');
}

// const searchProfessions = (studyLevels, businessSectors) => {
//     return Axios.get(`/search_professions/?study_levels=${studyLevels}&business_sectors=${businessSectors}`);
// }


// let searchProfessions = (studyLevels, businessSectors, seniorPosition, salaryRangeId, professionId) => {
//     let queryParams = [];

//     if (studyLevels != null) {
//         queryParams.push(`study_levels=${studyLevels}`);
//     }
//     if (businessSectors != null) {
//         queryParams.push(`business_sectors=${businessSectors}`);
//     }
//     if (seniorPosition != null) {
//         queryParams.push(`senior_position=${seniorPosition}`);
//     }
//     if (salaryRangeId != null) {
//         queryParams.push(`salary_range_id=${salaryRangeId}`);
//     } 

//     if (professionId != null) {
//         queryParams.push(`profession_id=${professionId}`)
//     }
//     let queryString = queryParams.length ? `?${queryParams.join('&')}` : '';
    
//     return Axios.get(`/search_professions/${queryString}`);
// };

// let searchProfessions = (studyLevels = null, businessSectors = null, seniorPosition = null, salaryRangeId = null, keywords=null, professionId = null) => {
//     let queryParams = {};
//
//     if (studyLevels) {
//         queryParams.study_levels = studyLevels;
//     }
//     if (businessSectors) {
//         queryParams.business_sectors = businessSectors;
//     }
//     if (seniorPosition !== null) {
//         queryParams.senior_position = seniorPosition;
//     }
//     if (salaryRangeId) {
//         queryParams.salary_range_id = salaryRangeId;
//     }
//     if (professionId) {
//         queryParams.profession_id = professionId;
//     }
//     if (keywords) {
//         queryParams.keywords = keywords;
//     }
//     console.log(queryParams, "queryParams");
//     return Axios.get('/search_professions/', { params: queryParams });
// };

let searchProfessions = (
    studyLevels = undefined,
    businessSectors = undefined,
    seniorPosition = undefined,
    salaryRangeId = undefined,
    keywords = undefined,
    professionId = undefined,
    userId=undefined,

) => {
    let queryParams = {};

    if (studyLevels) queryParams.study_levels = studyLevels;
    if (businessSectors) queryParams.business_sectors = businessSectors;
    if (seniorPosition !== undefined) queryParams.senior_position = seniorPosition;
    if (salaryRangeId) queryParams.salary_range_id = salaryRangeId;
    if (professionId) queryParams.profession_id = professionId;
    if (keywords) queryParams.keywords = keywords;
    if (userId) queryParams.user_id = userId;

    console.log(queryParams, "queryParams");

    return Axios.get('/search_professions', { params: queryParams });
};


const get35aProfessionsDescription = (professionId) => {
    if (!professionId) {
        return Promise.reject('User ID is required');
    }
    console.log("Fetching profession for professionId:", professionId);
    return Axios.get('/35a_professions_description/', {
        params: { profession_id: professionId }
    });
};

export const MetierService = {
    get35Professions,
    searchProfessions,
    get35aProfessionsDescription
}
