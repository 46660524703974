import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

export default function ConditionsGeneralesDialog({ open, onClose }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Conditions Générales</DialogTitle>
            <DialogContent className="text-justify">
                {/* Add the content for Conditions Générales here */}
                <p >Les présentes conditions générales d’utilisation régissent l’accès et l’utilisation du site linkyways.com/ ci-après dénommé « le Site », mis à disposition par la société LINKYWAYS, ci-après « LINKYWAYS ». Le Site est mis à disposition des utilisateurs, ci-après « Utilisateurs », intéressés par les services et les contenus liés aux formations et aux métiers recommandés via cette plateforme.
                    L’objectif du Site est de proposer des services liés à la formation, notamment en facilitant la mise en relation des Utilisateurs avec des établissements d’enseignement supérieur. Les informations fournies par les Utilisateurs, y compris leurs coordonnées, peuvent être utilisées à cette fin.
                    Le propriétaire du Site, LINKYWAYS, se réserve le droit de modifier, à tout moment et sans préavis, la présentation, la structure et/ou les fonctionnalités du Site, ainsi que tout ou partie des services proposés, ou encore d’ajouter de nouveaux services.
                    L’utilisation du Site par les Utilisateurs implique l’acceptation complète des présentes conditions générales d’utilisation, dans la version en vigueur au moment de l’accès au Site. LINKYWAYS se réserve également le droit de modifier ces conditions à tout moment sans préavis. Il est donc recommandé aux Utilisateurs de consulter régulièrement les conditions d’utilisation.
                    Certains services disponibles sur le Site peuvent être soumis à des conditions particulières qui complètent ou remplacent les présentes conditions générales d’utilisation. Avant d’utiliser ces services spécifiques, l’Utilisateur doit prendre connaissance et accepter les conditions particulières associées.
                </p>
                <p className="font-bold font-poppins text-sm">Conditions d’accès et d’utilisation</p>
                <p>
                    L’accès aux services et outils proposés par le Site ne nécessite généralement pas d’abonnement ou d’inscription préalable.
                    Toutefois, les informations fournies par les Utilisateurs dans le cadre de l’utilisation des services doivent être véridiques. L’Utilisateur s’engage à fournir des données authentiques et exactes dans les formulaires requis pour la souscription aux services. Ces informations, notamment les coordonnées fournies, peuvent être utilisées par LINKYWAYS dans le cadre de la mise en relation des Utilisateurs avec des établissements d’enseignement supérieur.
                    En cas d’usage malveillant, l’Utilisateur pourra être tenu responsable des préjudices directs ou indirects causés au Site, à LINKYWAYS ou à des tiers.

                </p>
                <p className="font-bold font-poppins text-sm">Cookies</p>
                <p>
                    Le Site utilise des outils de mesure d’audience et d’analyse du comportement des visiteurs dans le but d’améliorer son fonctionnement.
                    Ces outils utilisent des cookies de tiers. L’Utilisateur peut, en cas de non-respect des règles énoncées dans les présentes conditions,
                    être tenu responsable des dommages causés par son comportement.
                </p>
                <p className="font-bold font-poppins text-sm">                    Exclusion de garanties et responsabilité
                </p>
                <p>
                    Sauf mention expresse dans les présentes conditions générales d’utilisation, LINKYWAYS ne garantit pas l’exactitude, l’exhaustivité ou l’actualité des informations publiées. LINKYWAYS ne pourra être tenue responsable des dommages de toute nature qui pourraient découler d’erreurs ou d’omissions dans les informations, services ou outils proposés via le Site.
                    LINKYWAYS ne saurait être tenue responsable des services fournis par des tiers via la plateforme, ni des conséquences découlant de l'utilisation de ces services, tels que des actes de concurrence déloyale, des publicités illicites ou des contenus inexacts ou obsolètes.
                    LINKYWAYS se réserve le droit de modifier ou de retirer sans préavis tout contenu ne répondant pas aux critères de qualité définis.
                    Si un contenu inapproprié ou inexacte est détecté, les Utilisateurs peuvent contacter l’équipe à l’adresse suivante : contact@linkyways.com

                </p>
            </DialogContent>
            <DialogActions>
                <button
                    className="text-xl mt-1 font-bold hover:bg-gray-100 border-1 rounded-full px-2.5 pb-1"
                    onClick={onClose}
                    style={{
                        position: 'absolute',
                        right: 8,
                        top: 2,
                        zIndex: 1301, // Ensure it stays above the dialog
                    }}
                >
                    x
                </button>
            </DialogActions>
        </Dialog>
    );
}
