import React, { useState } from 'react';
import Modal from 'react-modal';
import { AuthService } from '../../../services/auth.service';
import { RenewPasswordModal } from './RenewPasswordModal';
import { notification } from 'antd';

Modal.setAppElement('#root');

export function OtpPasswordModal({ email, visible, onHide }) {
    const [otp, setOtp] = useState(new Array(6).fill(''));
    const [renewVisible, setRenewVisible] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [attempts, setAttempts] = useState(0);

    const handleOtpChange = (index, value) => {
        if (value.match(/^[0-9]$/)) {
            const newOtp = [...otp];
            newOtp[index] = value;
            setOtp(newOtp);

            if (index < 5) {
                document.getElementById(`otp-input-${index + 1}`).focus();
            }
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        if (otp.every((digit) => digit !== '')) {
            try {
                const otpCode = otp.join('');
                const response = await AuthService.confirmEmail(otpCode, email);
                
                if (response.data.status === 'success') {
                    setIsLoading(false);
                    setRenewVisible(true);
                    setShowAlert(false);
                    onHide();
                } else {
                    handleFailure();
                }
            } catch (error) {
                console.error("Échec de la vérification du code OTP :", error);
                handleFailure();
            }
        } else {
            console.log("Le code OTP est incomplet");
            setShowAlert(true);
            setIsLoading(false);
        }
    };

    const handleResendOtp = async () => {
        try {
            setShowAlert(false);
            await AuthService.forgotPassword(email);
            notification.success({
                message: 'Code de vérification renvoyé',
                description: 'Le code de vérification a été renvoyé avec succès à votre email.',
                placement: 'topRight',
            });
        } catch (error) {
            notification.error({
                message: 'Erreur lors de l\'envoi',
                description: 'Une erreur est survenue lors de la réinitialisation du code de vérification.',
                placement: 'topRight',
            });
            console.error("Error resending OTP:", error);
        }
    };

    const handleFailure = () => {
        setAttempts(prev => prev + 1);
        setShowAlert(true);
        setIsLoading(false);
        if (attempts >= 10) {
            onHide();
            alert("Vous avez atteint le nombre maximal de tentatives. Veuillez réessayer plus tard.");
        }
    };

    return (
        <>
            <Modal
                isOpen={visible}
                onRequestClose={() => {}} // Prevent modal from closing on outside click
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '400px',
                        padding: '2rem',
                        borderRadius: '0.5rem',
                        backgroundColor: 'white',
                        textAlign: 'center',
                        boxSizing: 'border-box',
                        position: 'relative', // Required for positioning the close icon
                    },
                }}
            >
                {/* Close Icon */}
                <button
                    onClick={onHide}
                    style={{
                        position: 'absolute',
                        top: '1rem',
                        right: '1rem',
                        background: 'none',
                        border: 'none',
                        cursor: 'pointer',
                    }}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                        <path d="M18 6 6 18"></path>
                        <path d="m6 6 12 12"></path>
                    </svg>
                </button>

                <h2 className="text-xl font-semibold mb-4">Vérifiez votre adresse e-mail</h2>
                {showAlert && (
                    <div className="bg-yellow-50 border border-yellow-200 text-sm text-yellow-800 rounded-lg p-2 mb-3" role="alert">
                        <div className="flex">
                            <div className="shrink-0">
                                <svg className="shrink-0 size-4 mt-0.5" xmlns="http://www.w3.org/2000/svg" width="20" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
                                    <path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z"></path>
                                    <path d="M12 9v4"></path>
                                    <path d="M12 17h.01"></path>
                                </svg>
                            </div>
                            <div className="ml-2 ms-0">
                                <div className="mt-1 text-sm text-yellow-700">
                                    Le code OTP que vous avez entré est invalide. Veuillez vérifier et réessayer.
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                <form onSubmit={handleSubmit} className="max-w-md mx-auto text-center">
                    <p className="text-[15px] text-black mb-4">
                        Un code de vérification à 6 chiffres a été envoyé à l'adresse e-mail suivante : 
                        <span className="text-[#1681FF]"> {email}</span>. 
                        Veuillez entrer ce code pour confirmer votre adresse e-mail avant de pouvoir réinitialiser votre mot de passe.
                    </p>

                    <div className="flex justify-center gap-2 sm:gap-3">
                        {otp.map((digit, index) => (
                            <input
                                key={index}
                                id={`otp-input-${index}`}
                                type="text"
                                inputMode="numeric"
                                maxLength="1"
                                onChange={(e) => handleOtpChange(index, e.target.value)}
                                className="w-10 h-12 sm:w-12 sm:h-14 text-center text-2xl text-black bg-slate-100 border border-transparent"
                            />
                        ))}
                    </div>

                    <button
                        type="submit"
                        className="mt-6 w-full inline-flex justify-center whitespace-nowrap rounded-lg bg-[#1681FF] px-4 py-2.5 text-sm font-medium text-white shadow-sm hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-300 transition-colors duration-150"
                        disabled={isLoading}
                    >
                        Confirmer
                    </button>
                </form>
                <div className="text-sm text-slate-500 mt-4">
                    Vous n'avez pas reçu le code ? <a className="font-medium text-[#1681FF] hover:text-indigo-600" onClick={handleResendOtp}>Renvoyer</a>
                </div>
            </Modal>
            <RenewPasswordModal email={email} visible={renewVisible} onHide={() => setRenewVisible(false)} />
        </>
    );
}
