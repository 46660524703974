import React, { useCallback, useEffect, useState } from "react";
import CustomCard from "../components/search/CustomCard";
import { UserService } from "../services/userforms.service";
import { GuidancesService } from "../services/guidance.service";
import tooltipSvg from "../images/search/tooltipSvg.svg";
import RatingModal from "../components/modal/RatingModal";
import CategoriesNoAuth from "../components/search/CategorieNoAuth";
import ArrowUp from "../images/arrow-up.svg";
import CustomSelect from "../components/dropDown/CustomSelect";
import { useProgress } from '../context/ProgressContext';
import ArrowDown from "../images/arrow-down.svg";
import HtmlTooltip from "../utils/HtmlTooltip";
import { SubmissionService } from "../services/submission.service";
import DropDownMobile from "../components/dropDown/DropDownMobile";
import '../style/sujets-preferes.css';
import { useNavigate } from "react-router-dom";
import { useAuth } from '../services/auth.provider';
import LayoutNo from "../utils/LayoutNo";
import { CryptoService } from "../services/crypte.service";
import { notification } from 'antd';

export default function SujetsPreferes() {
    const [categoriesWithSubjects, setCategoriesWithSubjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCards, setSelectedCards] = useState([]);
    const [multiSelectOptions, setMultiSelectOptions] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [rating, setRating] = useState({});
    const [selectedCategories, setSelectedCategories] = useState([]);
    const [isMobileCategoriesOpen, setIsMobileCategoriesOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const { setFinalSectionProgress } = useProgress();
    const [searchTerm, setSearchTerm] = useState("");
    const [filteredCategories, setFilteredCategories] = useState(categoriesWithSubjects);
    const [tempSelectedCard, setTempSelectedCard] = useState(null);
    const navigate = useNavigate();
    const [userId, setUserId] = useState(null);
    const { isAuthenticated } = useAuth();
    const [isSmallScreen, setIsSmallScreen] = useState(false);
    const [tooltipVisible, setTooltipVisible] = useState(false);

    // Detect screen size
    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        handleResize(); // Set initial value
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    const toggleTooltip = () => {
        setTooltipVisible(!tooltipVisible);
    };

    useEffect(() => {

        if (isAuthenticated) {
            const user = localStorage.getItem('user_id');
            console.log("user ==> ", user);
            const decryptedId = CryptoService.decryptData(user)
            setUserId(decryptedId);
        }

        setFinalSectionProgress(parseInt(93, 10));

        fetchCategoriesAndSubjects();

        document.body.classList.add("overflow-y-hidden");
        return () => {
            document.body.classList.remove("overflow-y-hidden");
        };
    }, []);

    useEffect(() => {
        fetchUserSubmissionData();
    }, [isAuthenticated, userId]);
    

    const fetchUserSubmissionData = async () => {
        if (isAuthenticated && userId) {
            console.log(userId, "userId");
            try {
                const response = await SubmissionService.getUserSubmissionData(parseInt(userId));
                if (response?.data?.status === 'success') {
                    const userSubjects = response.data.data.user_subjects;
                    console.log(userSubjects, "submissionResponse.user_subjects");
    
                    // Sync with local storage
                    syncLocalStorageWithServerData(userSubjects);
    
                    // Update state with fetched data
                    setSelectedCategories(userSubjects.map(subject => ({
                        id: subject.subject_id,
                        label: subject.sub_name,
                        rating: subject.subject_level,
                    })));
                    setSelectedCards(userSubjects.map(subject => subject.subject_id));
                    setRating(userSubjects.reduce((acc, subject) => {
                        acc[subject.subject_id] = subject.subject_level;
                        return acc;
                    }, {}));
                } else {
                    console.error("Error: Unexpected response format or status");
                }
            } catch (error) {
                console.error("Error fetching submission data: ", error);
            }
        }
    };
    
    const syncLocalStorageWithServerData = (serverData) => {
        const structuredData = serverData.map(subject => ({
            id: subject.subject_id,
            label: subject.sub_name,
            rating: subject.subject_level,
        }));
    
        localStorage.setItem('selectedCategories', JSON.stringify(structuredData));
        localStorage.setItem('selectedCards', JSON.stringify(structuredData.map(item => item.id)));
    
        const ratings = {};
        structuredData.forEach(item => {
            ratings[item.id] = item.rating;
        });
        localStorage.setItem('ratings', JSON.stringify(ratings));
    };
    
    useEffect(() => {
        localStorage.setItem('selectedCategories', JSON.stringify(selectedCategories));
        localStorage.setItem('selectedCards', JSON.stringify(selectedCards));
    }, [selectedCategories, selectedCards]);

    const fetchCategoriesAndSubjects = useCallback(async (categoryIds = []) => {
        try {
            let response = await UserService.getSubjectsCategries();
            if (response && response.data && response.data.data) {
                let categories = response.data.data;

                setMultiSelectOptions(categories);
                console.log("categoryIds ", categoryIds);

                const filteredCategories = categoryIds.length > 0
                    ? categories.filter(category => categoryIds.includes(category.cat_id))
                    : categories;

                console.log("filteredCategories ", filteredCategories);

                const fetchSubjectsPromises = filteredCategories.map(async (category) => {
                    let subjectResponse = await GuidancesService.getIKISubjects(null, category.cat_id);
                    let subjects = subjectResponse?.data?.data || [];
                    return {
                        category: category.cat_name,
                        subjects: subjects,
                    };
                });

                const results = await Promise.all(fetchSubjectsPromises);
                setCategoriesWithSubjects(results);
                setFilteredCategories(results);

            } else {
                throw new Error("Invalid response structure");
            }
        } catch (error) {
            console.error("Error occurred during fetching process:", error);
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, []);


    const handleDropdownChange = (selected) => {
        setSelectedOption(selected);
        console.log("fetch ", selected);

        selected.forEach((item, index) => {
            console.log(`Item ${index}:`, item);
        });

        const selectedIds = selected.map(opt => opt.cat_id);

        console.log("selectedIds", selectedIds);

        if (selectedIds.length > 0) {
            fetchCategoriesAndSubjects(selectedIds);
        } else {
            fetchCategoriesAndSubjects();
        }
    };


    const handleCardClick = (id, subjectName) => {
        const isAlreadySelected = selectedCards.includes(id);

        if (isAlreadySelected) {
            handleDeleteCategory(id);
        } else {
            if (selectedCategories.length < 7) {
                setTempSelectedCard({ id, label: subjectName });
                openModal();
            } else {
                alert("Vous pouvez sélectionner jusqu'à 7 sujets seulement.");
            }
        }
    };

    const handleRatingChange = (newRating) => {
        if (tempSelectedCard) {
            const { id, label } = tempSelectedCard;
    
            setRating(prev => {
                const updatedRating = { ...prev, [id]: newRating };
                updateLocalStorage(id, label, newRating);
                return updatedRating;
            });
    
            const updatedSelectedCategories = [...selectedCategories];
            const categoryIndex = updatedSelectedCategories.findIndex(cat => cat.id === id);
    
            if (categoryIndex !== -1) {
                updatedSelectedCategories[categoryIndex].rating = newRating;
            } else {
                updatedSelectedCategories.push({ id, label, rating: newRating });
            }
    
            setSelectedCategories(updatedSelectedCategories);
    
            if (!selectedCards.includes(id)) {
                setSelectedCards(prevSelected => [...prevSelected, id]);
            }
    
            setTempSelectedCard(null);
            closeModal();
        }
    };
    

    const updateLocalStorage = (itemId, itemName, newRating) => {
        const storedCategories = JSON.parse(localStorage.getItem('selectedCategories')) || [];
        const storedRatings = JSON.parse(localStorage.getItem('ratings')) || {};

        const updatedCategories = [...storedCategories.filter(cat => cat.id !== itemId), { id: itemId, label: itemName, rating: newRating }];
        localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));

        storedRatings[itemId] = parseInt(newRating);
        localStorage.setItem('ratings', JSON.stringify(storedRatings));
    };


    const handleDeleteCategory = (id) => {
        console.log(id, "handleDeleteCategory");
        setSelectedCategories(prev => prev.filter(cat => cat.id !== id));
        setSelectedCards(prevSelected => prevSelected.filter(cardId => cardId !== id));
    
        const storedCategories = JSON.parse(localStorage.getItem('selectedCategories')) || [];
        const updatedCategories = storedCategories.filter(cat => cat.id !== id);
        localStorage.setItem('selectedCategories', JSON.stringify(updatedCategories));
    
        const storedRatings = JSON.parse(localStorage.getItem('ratings')) || {};
        delete storedRatings[id];
        localStorage.setItem('ratings', JSON.stringify(storedRatings));
    };
    


    const openModal = () => setShowModal(true);

    const closeModal = () => {
        setShowModal(false);
        setTempSelectedCard(null);
        setCurrentItem(null);
    };

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
            </div>
        );
    }

    if (error) {
        return (
            <>
                <div className="flex justify-center items-center h-screen">
                    <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-blue-500 border-solid"></div>
                </div>
                Error: {error}
            </>
        );
    }

    const toggleMobileCategoriesOpen = () => {
        setIsMobileCategoriesOpen(!isMobileCategoriesOpen);
    };

    const handleSearchChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchTerm(query);

        const filtered = categoriesWithSubjects.filter(category => {
            const matchesCategory = category.category.toLowerCase().includes(query);

            const matchesSubjects = category.subjects.some(subject => {
                const subjectName = subject?.sub_name ? subject?.sub_name.toLowerCase() : '';
                const subjectDescription = subject.sub_description ? subject.sub_description.toLowerCase() : ''; 
                return subjectName.includes(query) || subjectDescription.includes(query);
            });

            return matchesCategory || matchesSubjects;
        });

        setFilteredCategories(filtered);
    };
    const submitUserData = async () => {
        const subjectsSelectedData = JSON.parse(localStorage.getItem('ratings')) || {};
        console.log(subjectsSelectedData, "subjectsSelectedData");
    
        if (Object.keys(subjectsSelectedData).length < 3) {
            notification.warning({
                message: '',
                description: 'Veuillez choisir au moins 3 sujets',
                placement: 'topRight',
            });            
            return;
        }
        try {
            if (isAuthenticated) {
                await SubmissionService.userSubmission(parseInt(userId)).then((response) => {
                    console.log(response, "response.data.")
                    if (response && response.status === "success") {
                        navigate("/guidance");
                    } else {
                        console.log("userSubmission Error ", response)
                    }
                })
            } else {
                navigate("/public/registration");
            }
        } catch (error) {
            console.error('Failed to submit user data:', error);
        }
    };


    return (
        <LayoutNo>
            <div className="flex flex-col h-screen bg-[#FAFAFA] md:mt-10 mt-20">
                <div className="flex flex-1">
                    {/* Main Content - Left Side */}
                    <main className={`flex flex-col flex-1  md:mt-2 ${isAuthenticated ? '' : 'lg:ml-64'}`}>
                        <div className="bg-gray-50 p-2 flex flex-col flex-grow ">
                            <header className="pt-1 lg:pt-2">
                                <div className="flex items-center mb-4">
                                    <h1 className="font-poppins text-[#141943] text-2xl">Sujets préférés</h1>
                                    {/*<HtmlTooltip*/}
                                    {/*    title={*/}
                                    {/*        <p className="text-sm leading-tight text-black">*/}
                                    {/*            Choisissez jusqu’à 7 sujets parmi ceux que vous appréciez le plus.*/}
                                    {/*            Vous pouvez rechercher des sujets par mots-clés ou par catégories.*/}
                                    {/*        </p>*/}
                                    {/*    }*/}
                                    {/*    placement="right"*/}
                                    {/*>*/}
                                    {/*    /!*<Button >*!/*/}
                                    {/*    <img src={tooltipSvg} alt="Info"*/}
                                    {/*        className="mb-3 object-cover ml-2 cursor-pointer" />*/}
                                    {/*    /!*</Button>*!/*/}
                                    {/*</HtmlTooltip>*/}
                                    {isSmallScreen ? (
                                        <div className="relative">
                                            <img
                                                src={tooltipSvg}
                                                alt="Info"
                                                className="mb-3 object-cover ml-2 cursor-pointer"
                                                onClick={toggleTooltip}
                                            />
                                            {tooltipVisible && (
                                                <div className="absolute w-40 bg-white p-2 text-black text-sm leading-tight rounded-md shadow-lg z-30">
                                                    Choisissez jusqu’à 7 sujets parmi ceux que vous appréciez le plus.
                                                    Vous pouvez rechercher des sujets par mots-clés ou par catégories.
                                                </div>
                                            )}
                                        </div>
                                    ) : (
                                        <HtmlTooltip
                                            title={
                                                <p className="text-sm leading-tight text-black">
                                                    Choisissez jusqu’à 7 sujets parmi ceux que vous appréciez le plus.
                                                    Vous pouvez rechercher des sujets par mots-clés ou par catégories.
                                                </p>
                                            }
                                            placement="right"
                                        >
                                            <img
                                                src={tooltipSvg}
                                                alt="Info"
                                                className="mb-3 object-cover ml-2 cursor-pointer"
                                            />
                                        </HtmlTooltip>
                                    )}

                                </div>

                                {/* Search and Dropdown */}
                                <div className="hidden lg:flex lg:flex-row items-center mb-4 space-x-4">
                                    <div
                                        className="flex items-center bg-[#E2E9EF] rounded-lg shadow-sm border border-gray-200 lg:w-[70%]">
                                        <svg
                                            className="w-1 h-4 text-gray-400"
                                            fill="none"
                                            stroke="currentColor"
                                            viewBox="0 0 24 24"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                                strokeWidth="2"
                                                d="M21 21l-4.35-4.35M4 10.5a6.5 6.5 0 1113 0 6.5 6.5 0 01-13 0z"
                                            ></path>
                                        </svg>
                                        <input
                                            type="text"
                                            value={searchTerm}
                                            onChange={handleSearchChange}
                                            className="bg-transparent py-2.5 w-full text-sm focus:outline-none"
                                            placeholder="Recherche par mot-clé"
                                        />
                                    </div>

                                    <div className="lg:w-[30%]">
                                        <CustomSelect
                                            options={multiSelectOptions}
                                            onChange={handleDropdownChange}
                                        />
                                    </div>
                                </div>
                                {/* Search and Dropdown for Mobile */}
                                <div className="lg:hidden w-full flex items-center space-x-4 mb-4">
                                    <input
                                        type="text"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        className="w-full bg-[#E2E9EF] px-5 py-2.5 block border-gray-200 rounded-lg"
                                        placeholder="Recherche par mot-clé"
                                    />
                                    <div className="relative z-20">
                                        <DropDownMobile
                                            options={multiSelectOptions}
                                            onChange={handleDropdownChange}
                                        />
                                    </div>
                                </div>
                                {/* Mobile Categories Toggle */}
                                <div className="lg:hidden mb-2">
                                    <button
                                        className="w-full flex justify-between font-poppins bg-white text-black border rounded-lg p-2"
                                        onClick={toggleMobileCategoriesOpen}
                                    >
                                        <p className="p-0 m-0">
                                            Sélectionnés
                                            <span className="text-gray-400 ml-2 ">
                                                {selectedCategories.length}/7
                                            </span>
                                        </p>
                                        <img
                                            src={isMobileCategoriesOpen ? ArrowUp : ArrowDown}
                                            alt={isMobileCategoriesOpen ? "ArrowUp" : "ArrowDown"}
                                            className=" cursor-pointer"
                                        />
                                    </button>
                                    {isMobileCategoriesOpen && (
                                        <div className="bg-white rounded-lg border mt-2">
                                            <CategoriesNoAuth
                                                items={selectedCategories}
                                                onDelete={handleDeleteCategory}
                                            />
                                        </div>
                                    )}
                                </div>

                            </header>

                            {/* Cards Section */}
                            <section className="flex-grow custom-scrollbar">
                                <div className="overflow-y-auto h-[calc(100vh-160px)] px-2 pb-24">
                                    {filteredCategories.map(({ category, subjects }) => (
                                        <div key={category} className="category-section mb-4">
                                            <h2 className="font-poppins text-lg text-[#141943] mt-1 mb-4">{category}</h2>
                                            <div className="grid grid-cols-1 gap-3 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 mb-10">
                                                {subjects.map((subject) => (
                                                    <CustomCard
                                                        key={subject.sub_id}
                                                        title={subject?.sub_name}
                                                        description={subject.sub_description}
                                                        image={subject.sub_url}
                                                        checked={selectedCards && selectedCards.includes(subject.sub_id)}
                                                        onClick={() => handleCardClick(subject.sub_id, subject?.sub_name)}
                                                    />
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </section>


                        </div>
                    </main>

                    {/* Desktop Sidebar - Right Side */}
                     <aside className="hidden lg:flex lg:flex-col -mt-2 w-1/4 bg-white border border-[#D0DBE7] h-[calc(100vh-95px)]">
                        <div className="flex-grow">
                            <CategoriesNoAuth
                                items={selectedCategories}
                                onDelete={handleDeleteCategory}
                            />
                        </div>

                        <hr className="border-gray-300 my-4" />

                        <div className="px-4">
                            <button
                                type="submit"
                                className="bg-[#1681FF] text-white py-2 px-4 w-full rounded-lg mb-4"
                                onClick={submitUserData}
                            >
                                Suivant
                            </button>
                        </div>
                    </aside>
                </div>

                {/* Fixed Button for Mobile */}
                <div className="fixed bottom-0 inset-x-0 bg-white shadow-lg p-2 lg:hidden">
                    <button
                        type="submit"
                        className="bg-[#1681FF] text-white rounded-lg py-2 px-6 w-full"
                        onClick={submitUserData}
                    >
                        Suivant
                    </button>
                </div>
            </div>

            {showModal && tempSelectedCard && (
                <RatingModal
                    visible={showModal}
                    item={tempSelectedCard}
                    rating={rating[tempSelectedCard.id]}
                    onClose={closeModal}
                    onRatingChange={handleRatingChange}
                />
            )}

        </LayoutNo>
    );

}
