import React, { useState, useEffect } from 'react';
import CountryCodes from '../../utils/json/CountryCodes.json';
import { Dropdown } from 'primereact/dropdown';

export default function CloseToFinishStep({ setUserData, data, errors }) {
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [phone_number, setPhoneNumber] = useState('');
    const [user_year_of_birth, setSelectedYear] = useState('');
    const [user_name, setName] = useState('');
    const [user_surname, setSurname] = useState('');
    const [postalCode, setPostalCode] = useState('');

    const years = Array.from({ length: 100 }, (_, i) => (new Date().getFullYear() - 10) - i);

    const countries = Array.isArray(CountryCodes) ? CountryCodes.map(({ name, dial_code, code }) => ({
        label: name,
        value: code,
        dialCode: dial_code,
        code: code
    })) : [];

    useEffect(() => {
        const updatedData = {
            country: selectedCountry,
            phone_number: phone_number,
            user_year_of_birth: user_year_of_birth,
            user_name: user_name,
            user_surname: user_surname,
            postalCode: postalCode,
        };

        if (JSON.stringify(data) !== JSON.stringify(updatedData)) {
            setUserData(updatedData);
        }
    }, [selectedCountry, phone_number, user_year_of_birth, user_name, user_surname, postalCode, data, setUserData]);

    const handleCountryChange = (e) => {
        console.log('Selected country:', e);
        //    const selectedOption = countries.find(country => country.value === e.value);
        console.log('Selected label:', e.value);
        setSelectedCountry(e.value);
    };

    const handlePhoneChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const dialCode = selectedCountry ? CountryCodes.find(country => country.code === selectedCountry)?.dial_code : '';

    const countryTemplate = (option) => {
        return (
            <div className="flex items-center">
                <img
                    alt={option.label}
                    src={`https://flagcdn.com/48x36/${option.value.toLowerCase()}.png`}
                    style={{ width: '18px', marginRight: '10px' }}
                />
                {option.label}
            </div>
        );
    };

    return (
        <div className="mt-5 px-2">
            <p className="font-bold text-2xl mb-2">Presque terminé!</p>
            <p className="text-sm mb-2">Renseignez vos coordonnées pour être contacté(e) par votre future école / université</p>
            <div className="rounded-md mx-auto px-2 py-3 max-w-3xl">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6">
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Nom*</label>
                        <input
                            type="text"
                            placeholder="Nom"
                            value={user_name}
                            onChange={(e) => setName(e.target.value)}
                            className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.user_name ? 'border border-red-500' : ''}`}
                        />
                        {errors.user_name && <p className="text-red-500 text-sm">{errors.user_name}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Prénom*</label>
                        <input
                            type="text"
                            placeholder="Prénom"
                            value={user_surname}
                            onChange={(e) => setSurname(e.target.value)}
                            className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.user_surname ? 'border border-red-500' : ''}`}
                        />
                        {errors.user_surname && <p className="text-red-500 text-sm">{errors.user_surname}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Code postal*</label>
                        <input
                            type="number"
                            placeholder="Code postal"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.postalCode ? 'border border-red-500' : ''}`}
                        />
                        {errors.postalCode && <p className="text-red-500 text-sm">{errors.postalCode}</p>}
                    </div>
                    <div className="flex.flex-col">
                        <label className="block text-sm font-medium text-[#141943E5] mb-2">Pays*</label>
                        <Dropdown
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            options={countries}
                            optionLabel="label"
                            optionValue="label"
                            placeholder="Sélectionnez un pays"
                            itemTemplate={countryTemplate}
                            className={`bg-white border border-[#D0DBE7] rounded-md w-full p-2 ${errors.country ? 'border-red-500' : ''}`}
                            showClear
                        />
                        {errors.country && <p className="text-red-500 text-sm">{errors.country}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Année de naissance*</label>
                        <select
                            value={user_year_of_birth || ''}
                            onChange={handleYearChange}
                            className={`bg-white border border-[#D0DBE7] rounded-md w-full p-3 ${errors.user_year_of_birth ? 'border-red-500' : ''}`}
                        >
                            <option value="" disabled>Sélectionnez l'année</option>
                            {years.map(year => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {errors.user_year_of_birth && <p className="text-red-500 text-sm">{errors.user_year_of_birth}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Téléphone*</label>
                        <div className="flex items-center">
                            <input
                                type="number"
                                value={phone_number}
                                onChange={handlePhoneChange}
                                placeholder="Numéro de téléphone"
                                className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.phone_number ? 'border border-red-500' : ''}`}
                            />
                        </div>
                        {errors.phone_number && <p className="text-red-500 text-sm">{errors.phone_number}</p>}
                    </div>
                </div>
            </div>
        </div>

    );
}


/*
import React, { useState, useEffect } from 'react';
import CountryCodes from '../../utils/json/CountryCodes.json';
import { Dropdown } from 'primereact/dropdown';

export default function CloseToFinishStep({ setUserData, data, errors }) {
    const [selectedCountry, setSelectedCountry] = useState(data.country || '');
    const [phone_number, setPhoneNumber] = useState(data.phone_number || '');
    const [user_year_of_birth, setSelectedYear] = useState(data.user_year_of_birth || '');
    const [user_name, setName] = useState(data.user_name || '');
    const [user_surname, setSurname] = useState(data.user_surname || '');
    const [postalCode, setPostalCode] = useState(data.postalCode || '');

    const years = Array.from({ length: 100 }, (_, i) => (new Date().getFullYear() - 10) - i);

    const countries = Array.isArray(CountryCodes) ? CountryCodes.map(({ code, name, dial_code }) => ({
        value: code,
        label: name,
        dialCode: dial_code
    })) : [];

    useEffect(() => {
        const updatedData = {
            country: selectedCountry,
            phone_number: phone_number,
            user_year_of_birth: user_year_of_birth,
            user_name: user_name,
            user_surname: user_surname,
            postalCode: postalCode,
        };

        if (JSON.stringify(data) !== JSON.stringify(updatedData)) {
            setUserData(updatedData);
        }
    }, [selectedCountry, phone_number, user_year_of_birth, user_name, user_surname, postalCode, data, setUserData]);

    const handleCountryChange = (e) => {
        setSelectedCountry(e.value);
    };

    const handlePhoneChange = (e) => {
        setPhoneNumber(e.target.value);
    };

    const handleYearChange = (e) => {
        setSelectedYear(e.target.value);
    };

    const dialCode = selectedCountry ? CountryCodes.find(country => country.code === selectedCountry)?.dial_code : '';

    const countryTemplate = (option) => {
        return (
            <div className="flex items-center">
                <img
                    alt={option.value}
                    src={`https://flagcdn.com/48x36/${option.value.toLowerCase()}.png`}
                    style={{ width: '18px', marginRight: '10px' }}
                />
                {option.label}
            </div>
        );
    };

    return (
        <div className="mt-5">
            <p className="font-bold text-2xl mb-2">Presque terminé!</p>
            <p className="text-sm mb-4">Veuillez remplir vos coordonnées</p>
            <div className="rounded-md mx-auto px-2 py-4 max-w-3xl">
                <div className="grid grid-cols-1 md:grid-cols-2 gap-x-4 gap-y-6">
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Nom*</label>
                        <input
                            type="text"
                            placeholder="Nom"
                            value={user_name}
                            onChange={(e) => setName(e.target.value)}
                            className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.user_name ? 'border border-red-500' : ''}`}
                        />
                        {errors.user_name && <p className="text-red-500 text-sm">{errors.user_name}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Prénom*</label>
                        <input
                            type="text"
                            placeholder="Prénom"
                            value={user_surname}
                            onChange={(e) => setSurname(e.target.value)}
                            className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.user_surname ? 'border border-red-500' : ''}`}
                        />
                        {errors.user_surname && <p className="text-red-500 text-sm">{errors.user_surname}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Code postal*</label>
                        <input
                            type="number"
                            placeholder="Code postal"
                            value={postalCode}
                            onChange={(e) => setPostalCode(e.target.value)}
                            className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.postalCode ? 'border border-red-500' : ''}`}
                        />
                        {errors.postalCode && <p className="text-red-500 text-sm">{errors.postalCode}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-medium text-[#141943E5] mb-2">Pays*</label>
                        <Dropdown
                            value={selectedCountry}
                            onChange={handleCountryChange}
                            options={countries}
                            optionLabel="label"
                            showClear
                            itemTemplate={countryTemplate}
                            placeholder="Sélectionnez un pays"
                            className={`bg-white border border-[#D0DBE7] rounded-md w-full p-2 ${errors.selectedCountry ? 'border-red-500' : ''}`}
                        />
                        {errors.selectedCountry && <p className="text-red-500 text-sm">{errors.selectedCountry}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Année de naissance*</label>
                        <select
                            value={user_year_of_birth || ''}
                            onChange={handleYearChange}
                            className={`bg-white border border-[#D0DBE7] rounded-md w-full p-3 ${errors.user_year_of_birth ? 'border-red-500' : ''}`}
                        >
                            <option value="" disabled>Sélectionnez l'année</option>
                            {years.map(year => (
                                <option key={year} value={year}>
                                    {year}
                                </option>
                            ))}
                        </select>
                        {errors.user_year_of_birth && <p className="text-red-500 text-sm">{errors.user_year_of_birth}</p>}
                    </div>
                    <div className="flex flex-col">
                        <label className="block text-sm font-inter text-[#141943E5] mb-2">Téléphone*</label>
                        <div className="flex items-center">
                            {dialCode && (
                                <div
                                    className="bg-gray-100 p-3 border border-[#D0DBE7] rounded-md mr-2"
                                >
                                    {dialCode}
                                </div>
                            )}
                            <input
                                type="number"
                                value={phone_number}
                                onChange={handlePhoneChange}
                                placeholder="Numéro de téléphone"
                                className={`bg-[#F2F2F2] p-3 rounded-md w-full ${errors.phone_number ? 'border border-red-500' : ''}`}
                            />
                        </div>
                        {errors.phone_number && <p className="text-red-500 text-sm">{errors.phone_number}</p>}
                    </div>
                </div>
            </div>
        </div>
    );
}
*/