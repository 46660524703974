import React, { useState, useEffect } from "react";
import trashIcon from "../../images/modal/trashicon.svg";
import RatingComponent from "../../utils/Rating";
import RatingModal from "../modal/RatingModal";
import PropTypes from 'prop-types';

function CategoriesNoAuth({ items, onDelete }) {
    const [ratings, setRatings] = useState({});
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const [currentItem, setCurrentItem] = useState(null);
    const [hoveredItem, setHoveredItem] = useState(null);

    useEffect(() => {
        // Load ratings from local storage
        const storedRatings = JSON.parse(localStorage.getItem('ratings')) || {};
        setRatings(storedRatings);
    }, [items]);

    const updateLocalStorage = (itemId, newRating) => {
        const storedRatings = JSON.parse(localStorage.getItem('ratings')) || {};
        storedRatings[itemId] = newRating;
        localStorage.setItem('ratings', JSON.stringify(storedRatings));
        setRatings(storedRatings);  // Update the state with the new ratings
    };

    const handleDelete = (id, e) => {
        e.stopPropagation(); // Prevent triggering modal open click
        onDelete(id); // Call the passed onDelete function
        if (currentItem?.id === id) {
            setCurrentItem(null); // Deselect if the deleted item is the current item
        }
    };

    const openModal = (item) => {
        setCurrentItem(item);
        setShowUpdateModal(true);
    };

    const handleRatingChange = (newRating) => {
        if (currentItem) {
            updateLocalStorage(currentItem.id, newRating); // Update local storage for the specific item
            closeModal();
        }
    };

    const closeModal = () => {
        setShowUpdateModal(false);
        setCurrentItem(null); // Optionally clear the current item if needed
    };

    return (
        <div className="p-4 ">
            {/* Header Section */}
            <div className="flex justify-between mb-2">
                <div className="hidden md:flex items-center space-x-4">
                    <p className="text-[#141943] text-xl">Sélectionnés</p>
                    <p className="flex flex-row">
                    <span className=" text-2xl pb-1 ">{items.length} </span>
                    <span className=" text-1xl mt-2 ml-2"> /</span>
                        <span className="mt-2 text-sm pl-1"> 7</span>
                    </p>
                </div>
            </div>
    
            {/* Desktop View */}
            <div className="hidden md:block">
                {items.map((item) => (
                    <div
                        key={item.id}
                        className="flex items-center justify-between relative hover:bg-[#E9F2FB] rounded-md p-1"
                        onMouseEnter={() => setHoveredItem(item.id)}
                        onMouseLeave={() => setHoveredItem(null)}
                        onClick={() => openModal(item)}
                    >
                        <div className="flex items-center ">
                            {hoveredItem === item.id && (
                                <img
                                    src={trashIcon}
                                    alt="Delete"
                                    className="object-cover ml-1 cursor-pointer mr-2"
                                    onClick={(e) => handleDelete(item.id, e)}
                                    aria-label="Delete item"
                                />
                            )}
                            <div className="text-xs font-inter max-w-7rem">{item.label}</div>
                        </div>
    
                        <RatingComponent
                            ratingValue={ratings[item.id] || 0}
                            maxRating={3}
                            starColor="text-yellow-400"
                            inactiveStarColor="text-gray-300"
                            //size="w-8 h-8"
                        />
                    </div>
                ))}
            </div>
    
            {/* Mobile View */}
            <div className="block md:hidden">
                {items.map((item) => (
                    <div
                        key={item.id}
                        className="flex items-center justify-between mb-1.5 p-2 relative"
                        onClick={() => openModal(item)}
                    >
                        <div className="flex">
                            <div className="text-xs font-inter">{item.label}</div>
                        </div>
    
                        <RatingComponent
                            ratingValue={ratings[item.id] || 0}
                            maxRating={3}
                            starColor="text-yellow-400"
                            inactiveStarColor="text-gray-300"
                            size="w-8 h-8"
                        />
                    </div>
                ))}
            </div>
    
            {showUpdateModal && currentItem && (
                <RatingModal
                    visible={showUpdateModal}
                    item={currentItem}
                    rating={ratings[currentItem.id] || 0}
                    onClose={closeModal}
                    onRatingChange={handleRatingChange}
                />
            )}
        </div>
    );
  
}

CategoriesNoAuth.propTypes = {
    items: PropTypes.array.isRequired,
    onDelete: PropTypes.func.isRequired,
};

export default CategoriesNoAuth;