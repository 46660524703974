import Axios from './caller.service';

/**
 * AuthService module for handling user authentication.
 */
export const AuthService = {
    // /**
    //  * User login service
    //  * @param {string} email - User's email
    //  * @param {string} password - User's password
    //  * @returns {Promise} - Axios promise with the server response.
    //  */
    // login(email, password) {
    //     const data = {
    //         user_email: email,
    //         user_password: password
    //     };
    //     return Axios.post('/user/login', data)
    //         .catch(error => {
    //             // Handle login error
    //             console.error('Login failed:', error);
    //             throw error;
    //         });
    // },

    /**
     * User registration service
     * @param {object} stepsData - Details of the user
     * @returns {Promise} - Axios promise with the server response.
     */
    async signup(stepsData) {
        console.log(stepsData, "stepsData received in signup");
    
        const data = {
            user_name: stepsData.user_name || null,
            user_surname: stepsData.user_surname || null,
            user_year_of_birth: stepsData.user_year_of_birth || null,
            user_email: stepsData.user_email || null,
            user_password: stepsData.user_password || null,
            phone_number: stepsData.phone_number || null,
            code_postal: stepsData.code_postal || null,
            country: stepsData.country || null,
            bac_type: stepsData.bac_type || 0,
            studlevel_id: stepsData.studlevel_id || null,
            status_id: stepsData.status_id || null,
            area_stud_id: stepsData.area_stud_id || null,
            bac_spe_techno: stepsData.bac_spe_techno || null,
            bac_spe_pro: stepsData.bac_spe_pro || null
        };
    
        if (stepsData.bacSpeGenerals) {
            stepsData.bacSpeGenerals.forEach((value, index) => {
                data[`bac_spe_general${index + 1}`] = value || null;
            });
        }

        console.log(data, "signup data being sent to API");
        try {
            return await Axios.post('/user/signup', data);
        } catch (error) {
            console.error('Signup failed:', error);
            throw error;
        }
    },

    /**
     * Forgot password service
     * @param {string} email - User's email
     * @returns {Promise} - Axios promise with the server response.
     */

    /**
     * Confirm email service
     * @param {string} otp - One-time password (OTP) sent to the user
     * @param {string} email - User's email
     * @returns {Promise} - Axios promise with the server response.
     */
    async confirmEmail(otp, email) {
        try {
            return await Axios.post('/user/confirm-email', {}, {
                params: {
                    token: otp,
                    email: email
                }
            });
        } catch (error) {
            // Handle confirm email error
            console.error('Confirm email failed:', error);
            throw error;
        }
    },
    
/*
    let params = {};
    
    if (status !== null) {
        params.status = status;
    }
    
    if (graduate !== null) {
        params.graduate = graduate;
    }
    
    return Axios.get('/studies_level/', { params: params });
*/
    /**
     * Reset password service
     * @param {string} otp - One-time password (OTP) sent to the user
     * @param {string} email - User's email
     * @param {string} newPassword - New password
     * @returns {Promise} - Axios promise with the server response.
     */
    async resetPassword(email, newPassword) {
        const data = {
            email: email,
            new_password: newPassword
        };
        try {
            return await Axios.post('/user/reset-password/', data);
        } catch (error) {
            // Handle reset password error
            console.error('Reset password failed:', error);
            throw error;
        }
    },

    async forgotPassword(email) {
        const data = { email };
        try {
            return await Axios.post('/user/forget-password/', data);
        } catch (error) {
            // Handle forgot password error
            console.error('Forgot password failed:', error);
            throw error;
        }
    },

    async checkEmail(newEmail, replaceEmail, replaceEmailStatus) {
        let params = {};
        
        if (newEmail !== null) {
            params.new_email = newEmail;
        }
        
        if (replaceEmail !== null) {
            params.replace_email = replaceEmail;
        }

        params.replace_email_status = replaceEmailStatus;
        const queryString = new URLSearchParams(params).toString();
        console.log("checkEmail-params ", queryString)
        return Axios.post(`/user/check-email/?${queryString}`);
    }
};
