import React, { useEffect, useState } from "react";
import svgPattern from "../images/progress.svg";
import { UserService } from "../services/userforms.service";

const progressColors = ['#FFCD1F', '#141943', '#ED3984', '#1681FF', '#18BC81'];

export default function TraitsDeCarctere({ userId }) {
    const [userPersonalities, setUserPersonalities] = useState([]);
    const [tooltipVisible, setTooltipVisible] = useState(null);
    const [isSmallScreen, setIsSmallScreen] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsSmallScreen(window.innerWidth < 768);
        };

        handleResize(); // Set the initial screen size
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [userId]);

    const toggleTooltip = (id) => {
        setTooltipVisible(tooltipVisible === id ? null : id);
    };

    useEffect(() => {
        UserService.getUserSubmissionData(userId)
            .then((response) => {
                if (response.data.status === "success" && Array.isArray(response.data.data.user_personality)) {
                    setUserPersonalities(response.data.data.user_personality);
                } else {
                    console.error("Unexpected response format:", response.data);
                    setUserPersonalities([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching user submission data:", error);
                setUserPersonalities([]);
            });
    }, [userId]);

    return (
        <div className="mt-1 px-3">
            {userPersonalities.length > 0 ? (
                userPersonalities.map((item, index) => (
                    <div key={item.personality_trait_id} className="flex items-center justify-between">
                        <div className="text-xs font-inter text-start w-1/4  mr-4 relative">
                            <p
                                className={`-mb-3 text-[#141943] `}
                                onClick={() => isSmallScreen && toggleTooltip(item.personality_trait_id)}
                                onMouseEnter={() => !isSmallScreen && setTooltipVisible(item.personality_trait_id)}
                                onMouseLeave={() => !isSmallScreen && setTooltipVisible(null)}
                            >
                                {item.personality_trait}
                            </p>
                            {/*{tooltipVisible === item.personality_trait_id && (*/}
                            {/*    <div className="tooltip bg-gray-500 text-white p-2  rounded absolute z-10">*/}
                            {/*        {item.personality_trait}*/}
                            {/*    </div>*/}
                            {/*)}*/}
                        </div>

                        <div className="flex-1 ml-3 mt-3.5">
                            {/*<div className="relative flex items-center">*/}
                            {/*    /!* Image container *!/*/}
                            {/*    <div*/}
                            {/*        className="absolute rounded-md  top-1/2 transform  -translate-y-1/2 h-7 w-full" */}
                            {/*        style={{*/}
                            {/*            backgroundImage: `url(${svgPattern})`,*/}
                            {/*            backgroundSize: 'cover',*/}
                            {/*            zIndex: 2,*/}
                            {/*        }}*/}
                            {/*    />*/}
                            {/*    /!* Progress bar container *!/*/}
                            {/*    <div*/}
                            {/*        className="w-full rounded-sm h-5  bg-transparent  overflow-hidden "*/}
                            {/*        role="progressbar"*/}
                            {/*        aria-valuenow={item.person_trait_level * 20}*/}
                            {/*        aria-valuemin="0"*/}
                            {/*        aria-valuemax="100"*/}
                            {/*    >*/}
                            {/*        <div*/}
                            {/*            className="absolute inset-0 flex items-center justify-end text-xs p-1 text-white font-medium z-3"*/}
                            {/*            style={{*/}
                            {/*                backgroundColor: progressColors[index % progressColors.length],*/}
                            {/*                width: `${item.person_trait_level * 20}%`*/}
                            {/*            }}*/}
                            {/*        >*/}
                            {/*            {item.person_trait_level * 20}%*/}
                            {/*        </div>*/}
                            {/*    </div>*/}
                            {/*</div>*/}


                            <div className="relative flex items-center -mt-1">
                                {/* Image container wrapping the progress bar */}
                                <div
                                    className="relative rounded-md h-7 w-full px-1" // Use px-1 to give space on left and right
                                    style={{
                                        backgroundImage: `url(${svgPattern})`,
                                        backgroundSize: 'cover',
                                        backgroundRepeat: 'no-repeat',
                                        zIndex: 2,
                                    }}
                                >
                                    {/* Progress bar */}
                                    <div
                                        className="w-full  mt-1.5  bg-transparent overflow-hidden"
                                        role="progressbar"
                                        aria-valuenow={item.person_trait_level * 20}
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                    >
                                        <div
                                            className="relative flex items-center justify-end text-xs px-1 text-white font-medium"
                                            style={{
                                                backgroundColor: progressColors[index % progressColors.length],
                                                width: `${item.person_trait_level * 20}%`,
                                            }}
                                        >
                                            {item.person_trait_level * 20}%
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                ))
            ) : (
                <div className="animate-pulse">
                    <div className="h-4 bg-gray-300  mb-3"></div>
                    <div className="h-4 bg-gray-300  mb-3"></div>
                    <div className="h-4 bg-gray-300  mb-3"></div>
                    <div className="h-4 bg-gray-300  mb-3"></div>
                    <div className="h-4 bg-gray-300  mb-3"></div>
                </div>
            )}
        </div>
    );
}
