import React, { useState } from 'react';
import BgImg from "../images/bgimage.svg";
import { Link } from 'react-router-dom';
import logo from "../images/mobileTopBg.svg";
import StepperPage from './Stepper.component';

export default function Registration() {
    // const [currentStep, setCurrentStep] = useState(1);

    // const getBackgroundImage = () => {        
    //     return currentStep >= 5 ? BgImg1 : BgImg;
    // };

    return (
            <div className="flex flex-col lg:flex-row min-h-screen">
                <div className="relative lg:block lg:w-1/5 bg-gray-200">
                    <Link to={"/landing"}>


                    <img
                        alt="Background"
                        src={BgImg}
                        className="object-cover w-full h-full hidden lg:block" />
                    <img
                        alt="laststep"
                        src={logo}
                        className="w-full  lg:hidden object-cover" />
                    </Link>
                </div>

                <div className="w-full lg:w-4/5 bg-white flex flex-col justify-between">
                    <div className="max-w-2xl w-full mx-auto flex-grow flex flex-col justify-center">
                        <StepperPage />
                    </div>
                </div>
            </div>

    );
}


// import React, { useState } from 'react';
// import BgImg1 from "../images/lastStepsideimg.svg";
// import logo from "../images/logo.svg";
// import { LoginModal } from './modal/auth/LoginModal';
// import StepperPage from './Stepper.component';

// export default function Registration() {
//     const [isModalVisible, setIsModalVisible] = useState(false);
//     const [currentStep, setCurrentStep] = useState(1);

//     const getBackgroundImage = () => {
//         return currentStep === 10 || currentStep === 11 ? BgImg1 : BgImg;
//     };

//     const showModal = () => setIsModalVisible(true);
//     const hideModal = () => setIsModalVisible(false);

//     return (
//         <>
//             <div className="flex flex-col lg:flex-row min-h-screen">
//                 <div className="relative lg:block lg:w-1/5 bg-gray-200">
//                     <img
//                         alt="Background"
//                         src={getBackgroundImage()}
//                         className="object-cover w-full h-full hidden lg:block" />
//                     <img
//                         alt="laststep"
//                         src={logo}
//                         className="w-full  lg:hidden object-cover" />
//                 </div>

//                 <div className="w-full lg:w-4/5 bg-white flex flex-col justify-between bg-gray-100">
//                     <div className="max-w-2xl w-full mx-auto flex-grow flex flex-col justify-center">
//                         <StepperPage setCurrentStep={setCurrentStep} />
//                     </div>
//                 </div>
//             </div>
//             {/*<div className="text-center mt-2 mb-4 md:fixed bottom-0 left-0 right-0 mx-auto">*/}
//             {/*    <p className="text-sm py-2">*/}
//             {/*        Déjà inscrit(e)?{' '}*/}
//             {/*        <a href="#" onClick={showModal} className="text-black font-bold underline">*/}
//             {/*            S’identifier*/}
//             {/*        </a>*/}
//             {/*    </p>*/}
//             {/*</div>*/}
//             <LoginModal visible={isModalVisible} onHide={hideModal} />
//         </>

//     );
// }

