import axios from 'axios';

const Axios = axios.create({
    baseURL: 'https://linkyways-7cd89449a16f.herokuapp.com',
});

// AxiosInstance.interceptors.request.use(
//     config => {
//         const token = localStorage.getItem('jwt_token');
//         if (token) {
//             config.headers['Authorization'] = `Bearer ${token}`;
//         }
//         return config;
//     },
//     error => {
//         return Promise.reject(error);
//     }
// );

// Axios.interceptors.request.use((request) => {
//     if (accountService.isLogged()) {
//         request.headers.Authorization = 'Bearer ' + accountService.getToken();
//     }
//     return request;
// });

// Axios.interceptors.response.use(
//     (response) => response,
//     (error) => {
//         if (error.response.status === 401) {
//             accountService.logout();
//             window.location = '/auth/login';
//         } else {
//             return Promise.reject(error);
//         }
//     }
// );

export default Axios;
