
import React from 'react';
import { Carousel } from 'primereact/carousel';
import '../style/carousel.css';

const images = [
    { id: 235, src: 'https://linkystock.blob.core.windows.net/businesssectorsimages/Rectangle%2026102836-1-insurance.png', title: 'Actuaire  ' },
    { id: 351, src: 'https://linkystock.blob.core.windows.net/businesssectorsimages/Rectangle%2026102844-1-commerce.png', title: 'Commercial(e) terrain  ' },
    { id: 292,  src: 'https://linkystock.blob.core.windows.net/businesssectorsimages/Rectangle%2026102838-1-biotechnology.png', title: 'Ingénieur en biotechnologie ' },
    { id: 423,  src: 'https://linkystock.blob.core.windows.net/businesssectorsimages/Rectangle%2026102868-1-construction.png', title: 'Architecte' },
];

const CarouselFiliere = () => {

    const responsiveOptions = [
        {
            breakpoint: '1024px',
            numVisible: 4,
            numScroll: 4
        },
        {
            breakpoint: '768px',
            numVisible: 4,
            numScroll: 4
        },
        {
            breakpoint: '560px',
            numVisible: 1,
            numScroll: 1
        }
    ];


    const goToDetailPage = (id) => {
        window.open(`/details/${id}`, '_blank', 'noopener,noreferrer');
    };

    const itemTemplate = (item) => {
        return (
            <div className="carousel-item cursor-pointer  " onClick={() => goToDetailPage(item.id)}>
                <img src={item.src} alt={item.title} className="carousel-image" />
                <div className=" text-lg font-poppins font-bold text-white carousel-title backdrop-blur-sm  ">{item.title}</div>
            </div>
        );
    };

    return (
        <div className="carousel-container">
            <Carousel
                value={images}
                itemTemplate={itemTemplate}
                numVisible={4}
                numScroll={1}
                responsiveOptions={responsiveOptions}
                circular
                autoplayInterval={false}
                className="p-carousel-custom"
            />
        </div>
    );
};

export default CarouselFiliere;
