import React, { useState, useEffect } from "react";
import editIcon from "../images/editicon.svg";
import RatingComponent from "../utils/Rating";
import { UserService } from "../services/userforms.service";
import { Link } from "react-router-dom";

export default function Categories( {userId}) {
    const [userSubjects, setUserSubjects] = useState([]);
    const [hoveredSubjectId, setHoveredSubjectId] = useState(null);

    useEffect(() => {
        UserService.getUserSubmissionData(userId)
            .then((response) => {
                if (response.data.status === "success" && Array.isArray(response.data.data.user_subjects)) {
                    setUserSubjects(response.data.data.user_subjects);
                } else {
                    console.error("Unexpected response format:", response.data);
                    setUserSubjects([]);
                }
            })
            .catch((error) => {
                console.error("Error fetching user submission data:", error);
                setUserSubjects([]);
            });
    }, [userId]);

    const handleMouseEnter = (subjectId) => {
        setHoveredSubjectId(subjectId);
    };

    const handleMouseLeave = () => {
        setHoveredSubjectId(null);
    };

    return (
        <>
            <div className="flex justify-between  px-3  ">
                <div className="justify-start py-1">
                    <p className="font-poppins font-bold text-md  text-[#141943] ">
                        Sujets sélectionnés
                    </p>
                </div>
                <div className="justify-end pr-2 -mt-1">
                    <Link to={'/public/moteur'} >
                    <button className="p-1 border-2 rounded-md shadow-sm hover:bg-gray-300">
                        <img src={editIcon} alt="edit1" />
                    </button>
                    </Link>
                </div>
            </div>
            <div className="px-1 -mt-3 px-3">
                {userSubjects.length > 0 ? (
                    userSubjects.map((item) => (
                        <div
                            key={item.subject_id}
                            className="flex items-center mt-2 justify-between relative"
                            onMouseEnter={() => handleMouseEnter(item.subject_id)}
                            onMouseLeave={handleMouseLeave}
                        >
                            <div
                                className={`cursor-pointer font-inter text-xs whitespace-nowrap pt-1 text-[#141943] overflow-hidden sm:truncate md:max-w-[20ch]`}
                            >
                                <span className="block sm:hidden">
                                    {item.sub_name}
                                </span>
                                <span className="hidden  sm:block">
                                    {item.sub_name && item.sub_name.length > 20 ? item.sub_name.slice(0, 20) + "..." : item.sub_name || 'N/A'}
                                    {/*{item.sub_name}*/}
                                </span>
                            </div>
                            {hoveredSubjectId === item.subject_id && (
                                <div className="absolute top-full left-0 mt-1 bg-gray-700 text-white text-xs rounded py-1 px-2 z-10 shadow-lg whitespace-nowrap">
                                    {item.sub_name}
                                </div>
                            )}
                            <RatingComponent
                                ratingValue={item.subject_level}
                                //onRatingChange={(newRating) => setRating(newRating)}
                                maxRating={5}
                                starColor="text-yellow-400"
                                inactiveStarColor="text-gray-300"
                                size="min-w-6 p-1 "
                            />
                        </div>
                    ))
                ) : (
                    <div className="animate-pulse">
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  mb-3"></div>
                        <div className="h-4 bg-gray-300  mb-3"></div>
                    </div>                )}

            </div>
        </>
    );
}
