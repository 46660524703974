import Axios from './caller.service';

/**
 * Récupération de la liste des utilisateurs
 * @returns {Promise}
 */


let getQuestions = (question_search_by_page = null) => {
    let params = {};
    
    if (question_search_by_page !== null) {
        params.question_search_by_page = question_search_by_page;
    }
    
    return Axios.get('/IKI_questions/', { params: params });
}

let addQuestions = (userId, responses, subjectsLevel) => {
    const requestBody = {
        user_id: userId,
        questions_scores: responses.map(response => ({
            question_id: response.questionId,
            score: response.score
        })),
        subjects_level: subjectsLevel
    };

    return Axios.post('/UserSubmission/', requestBody)
        .then(response => {
            console.log('Data submitted successfully:', response.data);
        })
        .catch(error => {
            console.error('Error submitting data:', error);
        });
};

export const QuestionsService = {
    getQuestions, addQuestions
}
