import Layout from "../dashboardComponents/MainLayout";
import Bgimg from "../images/searchametierbg.svg"
import Searchicon from "../images/search"
import {useEffect, useState} from "react";
import CarouselSlider from "../utils/CarouselMetiers";
import React from "react";
import BrushIcon from "../images/brush.svg";
import CarouselSecteur from "../utils/CarouselSecteur";
import CarouselFiliere from "../utils/CarouselFiliere";
import {UserService} from "../services/userforms.service";
import CustomSelectSearch from "../components/dropDown/CustomSelectSearch";
import ArrowDown from "../images/arrowDown";

export default function SearchSystemFormation(){
  
    const [isOpen, setIsOpen] = useState(false);
    const [isSalaireOpen, setIsSalaireOpen] = useState(false);
    const [studyLevels, setStudyLevels] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [salaryRanges, setSalaryRanges] = useState([]); // New state for salary ranges

    const [businessSector, setBusinessSectors] = useState([]); // New state for salary ranges

    const [selectedStudyLevels, setSelectedStudyLevels] = useState([]); // For managing selected options
    const [selectedValue, setSelectedValue] = useState('Metiers');

    const [selectedOption, setSelectedOption] = useState('');


    const handleOptionClick = (option) => {
        setSelectedOption(option.salary_range);
        setIsSalaireOpen(false);

    };

    const handleSelect = (value) => {
        setSelectedValue(value);
        setIsOpen(false);
    };


    useEffect(() => {
        const fetchStudyLevels = async () => {
            try {
                const response = await UserService.getStudiesLevel();
                setStudyLevels(response.data.data);
                console.log(response.data.data);
                setLoading(false);
            } catch (error) {
                setError(error);
                setLoading(false);
            }
        };

        const fetchSalaryRanges = async () => {
            try {
                const response = await UserService.getSalaireRange();
                setSalaryRanges(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.error("Error fetching salary ranges:", error);
            }
        };

        const fetchBusinessSector = async () => {
            try {
                const response = await UserService.getBusinessSector();
                setBusinessSectors(response.data.data);
                console.log(response.data.data);
            } catch (error) {
                console.error("Error fetching salary ranges:", error);
            }
        };


        fetchBusinessSector();

        fetchStudyLevels();
        fetchSalaryRanges();
    }, []);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>Error loading data: {error.message}</p>;

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const toggleSalaireDropdown = () => setIsSalaireOpen(!isSalaireOpen);

    const handleSelectChange = (selectedOptions) => {
        setSelectedStudyLevels(selectedOptions);
    };

    return(
        <Layout>
        <div>
            <div
                className="relative w-full h-48 mt-14 rounded-xl bg-cover bg-center"
                style={{ backgroundImage: `url(${Bgimg})` }}
            >
                <div className="absolute top-8 left-0  right-0 flex justify-center items-center">
                    <p className="font-bold md:text-5xl text-[#141943]">Explorez votre avenir</p>
                </div>

                <div className="absolute mt-5 inset-0 flex items-center justify-center">

                    <div className="bg-white w-3/4 md:w-2/3 lg:w-2/4   rounded-lg shadow-lg flex items-center space-x-2 p-1">

                        <div className="flex-grow">
                            <input
                                type="text"
                                placeholder="Search..."
                                className="w-full p-1 text-sm text-gray-700 rounded-l focus:outline-none"

                            />
                        </div>

                        <button type="button" className="flex shrink-0 justify-center items-center gap-2 size-[38px] text-sm font-medium rounded-lg border border-blue-500 bg-transparent text-white   ">
                           <Searchicon/>
                        </button>


                        <div className="relative inline-block text-left">
                            <button
                                id="dropdownDefaultButton"
                                onClick={toggleDropdown}
                                type="button"
                                className="text-white bg-[#1681FF] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-3 py-2.5 text-center inline-flex items-center pr-5">
                                {selectedValue}
                                <svg width="21" height="20" className="ml-2" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M17.5 7L10.5 14L3.5 7" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>

                            </button>

                            {isOpen && (
                                <div className="absolute mt-2  rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 z-20">
                                    <div className="py-1">
                                        <button
                                            onClick={() => handleSelect('Metiers')}
                                            className="block px-4 py-2 text-sm text-gray-700"
                                        >
                                             Metiers
                                        </button>
                                        <button
                                            onClick={() => handleSelect('Formations')}
                                            className="block px-4 py-2 text-sm text-gray-700 "
                                        >
                                            Formations
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
            <div>
                <div className="w-full flex flex-wrap justify-between items-center py-2">
                    <div className="flex flex-wrap gap-2 w-full lg:w-auto">
                        <div className="w-full sm:w-1/2 lg:w-auto">

                            <CustomSelectSearch
                                options={studyLevels}
                                onChange={handleSelectChange}
                                optionKey="studlevel_id"
                                optionLabel="study_level"
                                placeholder="Niveau d'études"
                            />

                        </div>
                        <div className="w-full sm:w-1/2 lg:w-auto">
                            <CustomSelectSearch
                                options={businessSector}
                                onChange={handleSelectChange}
                                optionKey="business_sect_id"
                                optionLabel="business_sect"
                                placeholder="Secteur d'activité"
                            />
                        </div>
                        <div className="w-full sm:w-1/2 lg:w-auto">

                            <div className="relative ">
                                <button
                                    className="flex justify-between items-center w-full px-4 py-2.5 border border-[#D0DBE7] bg-white rounded-lg focus:outline-none"
                                    onClick={toggleSalaireDropdown}
                                >
                                    {selectedOption || 'Salaire      '}<span className="text-transparent">-----</span> {/* Added extra spaces here */}

                                    <span className="ml-2"><ArrowDown /></span>
                                </button>
                                {isSalaireOpen && (
                                    <div
                                    className="absolute left-0 mt-2 w-full bg-white border border-[#D0DBE7] rounded-lg shadow-lg z-20"
                                        >
                                        <ul className="max-h-60 overflow-y-auto py-2">                                        {salaryRanges.map((range) => (
                                            <li
                                                key={range.salary_range_id}
                                                className="px-2 py-2 cursor-pointer hover:bg-gray-100"
                                                onClick={() => handleOptionClick(range)}
                                            >
                                                {range.salary_range}
                                            </li>
                                        ))}
                                    </ul>
                                    </div>
                                )}
                            </div>

                        </div>
                    </div>

                    <div className="justify-end mt-2  lg:mt-0">
                        <button className="flex  gap-2 text-xs px-4 py-2 border-2 rounded-md shadow-sm hover:bg-gray-300">
                            <img src={BrushIcon} alt="edit" className="w-6 h-6" />
                            <span className="whitespace-nowrap p-1">Tout effacer</span>
                        </button>
                    </div>
                </div>
            </div>

            <div>
                <div className="justify-start">
                    <p className="font-semibold font-poppins text-[28px] text-[#141943] p-1">
                        Métiers populaires
                    </p>
                </div>
                <CarouselSlider/>
                <div className="justify-start">
                    <p className="font-semibold font-poppins text-[28px] text-[#141943] p-1">
                        Secteurs d'activité clés
                    </p>
                </div>
                <CarouselSecteur/>
                <div className="justify-start">
                    <p className="font-semibold font-poppins text-[28px] text-[#141943] p-1">
                        Filières
                    </p>
                </div>
                <CarouselFiliere/>

            </div>
        </div>
        </Layout>
    )
}