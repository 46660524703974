import React, { useState } from 'react';
import Modal from 'react-modal';
import { SuccessResetModal } from './SuccessResetModal';
import EyeHide from "../../../images/eye-hide.svg";
import EyeShow from "../../../images/eye-view.svg";
import { AuthService } from '../../../services/auth.service';

Modal.setAppElement('#root'); 

export function RenewPasswordModal({ email, visible, onHide }) {
    const [resetVisible, setResetVisible] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setPasswordsMatch(e.target.value === confirmPassword);
    };

    const handleConfirmPasswordChange = (e) => {
        setConfirmPassword(e.target.value);
        setPasswordsMatch(password === e.target.value);
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);

        e.preventDefault();
        if (passwordsMatch) {
            try {
                const response = await AuthService.resetPassword(email, password);
                if (response.data.status === 'success') {
                    setIsLoading(false);
                    setResetVisible(true);
                    handleClose();
                } else {
                    setIsLoading(false);
                    console.log(response.message || 'An error occurred');
                }
            } catch (error) {
                setIsLoading(false);
                console.log('Login failed', error);
            }

        } else {
            // !passwordsMatch || !password || !confirmPassword
            setIsLoading(true);
        }

    };

    const handleClose = () => {
        setPassword('');
        setConfirmPassword('');
        setPasswordsMatch(true);
        setShowPassword(false);
        onHide();
    };

    return (
        <>
            <Modal
                isOpen={visible}
                onRequestClose={onHide}
                style={{
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    },
                    content: {
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        transform: 'translate(-50%, -50%)',
                        width: '90%',
                        maxWidth: '400px',
                        padding: '2rem',
                        borderRadius: '0.5rem',
                        backgroundColor: 'white',
                        textAlign: 'center',
                        boxSizing: 'border-box',
                    },
                }}
            >
                <button
                    type="button"
                    onClick={onHide}
                    style={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        background: 'none',
                        border: 'none',
                        fontSize: '24px',
                        cursor: 'pointer',
                    }}
                >
                    &times;
                </button>
                <h2 className="text-xl font-semibold mb-4">Crée un nouveau mot de passe</h2>
                <form onSubmit={handleSubmit} className="space-y-4">
                    <div className="w-full">
                        <label htmlFor="password" className="block text-sm font-medium mb-2 text-left">
                            Mot de passe
                        </label>
                        <div className="relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="password"
                                value={password}
                                onChange={handlePasswordChange}
                                className={`py-2 px-4 block w-full border ${
                                    passwordsMatch ? 'border-gray-300' : 'border-red-500'
                                } rounded-lg text-sm focus:border-gray-300 focus:ring-0 transition duration-200 ease-in-out`}
                                placeholder="********"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500"
                            >
                                {showPassword ? (
                                    <img src={EyeShow} alt="Afficher le mot de passe" className="h-6 w-6" />
                                ) : (
                                    <img src={EyeHide} alt="Masquer le mot de passe" className="h-6 w-6" />
                                )}
                            </button>
                        </div>
                    </div>
                    <div className="w-full">
                        <label htmlFor="confirm-password" className="block text-sm font-medium mb-2 text-left">
                            Confirmez le mot de passe
                        </label>
                        <div className="relative">
                            <input
                                type={showPassword ? 'text' : 'password'}
                                id="confirm-password"
                                value={confirmPassword}
                                onChange={handleConfirmPasswordChange}
                                className={`py-2 px-4 block w-full border ${
                                    passwordsMatch ? 'border-gray-300' : 'border-red-500'
                                } rounded-lg text-sm focus:border-gray-300 focus:ring-0 transition duration-200 ease-in-out`}
                                placeholder="********"
                            />
                            <button
                                type="button"
                                onClick={togglePasswordVisibility}
                                className="absolute inset-y-0 right-0 flex items-center px-3 text-gray-500"
                            >
                                {showPassword ? (
                                    <img src={EyeShow} alt="Afficher le mot de passe" className="h-6 w-6" />
                                ) : (
                                    <img src={EyeHide} alt="Masquer le mot de passe" className="h-6 w-6" />
                                )}
                            </button>
                        </div>
                    </div>
                    {!passwordsMatch && (
                        <p className="text-red-500 text-sm">Les mots de passe ne correspondent pas.</p>
                    )}
                    <div className="mb-1">
                        <button
                            type="submit"
                            className={`w-full py-2 px-4 text-white font-medium rounded-lg bg-[#1681FF] ${
                                passwordsMatch && password && confirmPassword ? 'hover:bg-blue-700' : 'cursor-not-allowed'
                            } focus:outline-none focus:ring-2 focus:ring-offset-2`}
                            disabled={isLoading}
                        >
                            Continuer
                        </button>
                    </div>
                </form>
            </Modal>
            <SuccessResetModal visible={resetVisible} onHideSuccessReset={() => setResetVisible(false)} />
        </>
    );
}
